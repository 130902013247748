export enum ITEM_STATUS {
  ONGOING = "ongoing",
  READY = "ready",
  APPROVED = "approved",
  DRAFT = "draft",
}

export type RiskCategories = 'High' | 'Low';
export type ItemStatus = (typeof ITEM_STATUS)[keyof typeof ITEM_STATUS];

export type Item = {
  id: string;
  date_added?: string;
  start_date_of_event: string;
  end_date_of_event: string;
  day: string;
  title: string;
  sub_header?: string;
  geolocation?: {
    latitude?: number;
    longitude?: number
  };
  ric_region_weekly: string;
  ric_region_daily: string;
  country: string;
  state_or_province: string;
  settlement: string;
  threat_risk_categories: string[];
  threat_risk_subcategory: string;
  threat_level: string;
  threat_rating_security: string;
  threat_rating_operations: string;
  threat_rating_brand_and_reputation: string;
  themes_or_tags: string[];
  threat_actors?: string[];
  details: string;
  assessment_or_comment: string;
  advisory?: string;
  source_links?: string[];
  organization_specific_ids?: string[];
  sector_specific?: string[];
  analyst_notes: string;
  recurrence: boolean;
  date_last_updated: string;
  added_by: string;
  last_updated_by?: string;
  is_reviewed: boolean;
  date_reviewed: string;
  reviewed_by: string;
  set_reminder: string;
  parent_item: string;
  image_url: string;
  is_deleted: boolean;
  is_private: boolean;
  privacy_level: string;
  type: string;
  event_status?: string;
  region: string;
  item_status?: ItemStatus;
  is_internal: boolean;
  image_source_link?: string;
  is_in_sendouts?: boolean;
  is_gold_brief?: boolean;
};

export type CollectItem = Partial<Item & { selected?: boolean }>;
