import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { appSlice } from "./store/app/AppSlice";
import { briefsApi } from "./store/briefs/BriefsApi";
import { briefsSlice } from "./store/briefs/BriefsSlice";
import { draftsSlice } from "./store/drafts/DraftsSlice";
import { itemSlice } from "./store/item/ItemSlice";
import { itemsApi } from "./store/items/ItemsApi";
import { itemsSlice } from "./store/items/ItemsSlice";
import errorMiddleware from "./store/middleware/errorMiddleware";
import { previewApi } from "./store/preview/PreviewApi";
import { reportsSlice } from "./store/reports/ReportsSlice";
import { sendoutsApi } from "./store/sendouts/SendoutsApi";
import { sendoutsSlice } from "./store/sendouts/SendoutsSlice";
import { toolbarSlice } from "./store/toolbar/ToolbarSlice";
import { userApi } from "./store/user/UserApi";
import { userSlice } from "./store/user/UserSlice";
import { previewSlice } from "./store/preview/PreviewSlice";

export const sliceReducers = {
  [appSlice.reducerPath]: appSlice.reducer,
  [briefsSlice.reducerPath]: briefsSlice.reducer,
  [draftsSlice.reducerPath]: draftsSlice.reducer,
  [itemSlice.reducerPath]: itemSlice.reducer,
  [itemsSlice.reducerPath]: itemsSlice.reducer,
  [reportsSlice.reducerPath]: reportsSlice.reducer,
  [toolbarSlice.reducerPath]: toolbarSlice.reducer,
  [userSlice.reducerPath]: userSlice.reducer,
  [sendoutsSlice.reducerPath]: sendoutsSlice.reducer,
  [previewSlice.reducerPath]: previewSlice.reducer,
};

export const apiReducers = {
  [briefsApi.reducerPath]: briefsApi.reducer,
  [itemsApi.reducerPath]: itemsApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [sendoutsApi.reducerPath]: sendoutsApi.reducer,
  [previewApi.reducerPath]: previewApi.reducer,
};

export const middlewares = [errorMiddleware, briefsApi.middleware, itemsApi.middleware, userApi.middleware, sendoutsApi.middleware, previewApi.middleware];

export const store = configureStore({
  reducer: { ...sliceReducers, ...apiReducers },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["items/setPreview"],
        ignoredPaths: ["items.preview.item"],
      },
    }).concat(...middlewares),
});

export type IRootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<IRootState>();
