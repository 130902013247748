
import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";

export const selectSendouts = ({ sendouts: { data } }: IRootState) => data;

export const selectSendoutsStatus = ({ sendouts: { status } }: IRootState) => status;

export const selectSendoutsShowMore = ({ sendouts: { showMore } }: IRootState) => showMore;

export const selectSendoutsPage = ({ sendouts: { page } }: IRootState) => page;

export const selectSendoutsFilters = ({ sendouts: { filters } }: IRootState) => filters;

export const selectSendoutsSelectedId = ({ sendouts: { selectedId } }: IRootState) => selectedId;

export const selectSendoutsEditedId = ({ sendouts: { editedId } }: IRootState) => editedId;

export const selectPreviewedSendout = ({ sendouts: { previewedSendoutId } }: IRootState) => previewedSendoutId;

export const selectSendoutBySelectedId = createSelector(
  [selectSendouts, selectSendoutsSelectedId],
  (sendouts, selectedId) => sendouts?.find((s) => s.id === selectedId)
);

export const selectSendoutByEditedId = createSelector(
  [selectSendouts, selectSendoutsEditedId],
  (sendouts, editedId) => sendouts?.find((s) => s.id === editedId)
);
