import { ButtonIcon } from "@secuis/ccp-react-components";
import React from "react";
import { useAppSelector } from "../../../../store";
import { selectToolbar } from "../../../../store/toolbar/ToolbarSelectors";
import { useToolbar } from "../ToolbarHooks";
import { FilterWrapper } from "./FilterMobileButton.styles";
import { FiltersUsedIndicator } from "./FiltersUsedIndicator";

export const FilterMobileButton = () => {
  const { isOpen: isExpanded } = useAppSelector(selectToolbar);
  const { handleExpand: toggleExpanded } = useToolbar();

  return (
    <FilterWrapper onClick={() => toggleExpanded(!isExpanded)} data-testid="filter-wrapper">
      <ButtonIcon mode="stateless" icon="Filter" iconSize="L" color="primary" />
      {!isExpanded && <FiltersUsedIndicator />}
    </FilterWrapper>
  );
};
