import { Breakpoints, Button, LoadingIndicator, Text, Title, useHasMaxWidth } from "@secuis/ccp-react-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppsEnum, IntercomTopic, useIntercom } from "../../../../hooks/useIntercom";
import { useReportDataSections } from "../../../../hooks/useReportDataSections";
import { useSection } from "../../../../hooks/useSection";
import { Report } from "../../../../models/ReportModel";
import { SECTION_STATUS } from "../../../../models/SectionModel";
import { useAppDispatch } from "../../../../store";
import { reportsActions } from "../../../../store/reports/ReportsSlice";
import { useLoggedUser } from "../../../../store/user/LoggedUserHooks";
import { useUser } from "../../../../store/user/UserHooks";
import { transformNames } from "../../../../utilities/reportUtils";
import { getFinalSections } from "../../../../utilities/sectionUtils";
import { getDataFromBE } from "../../../../utilities/userUtils";
import { getSectionsContent } from "../../../../utilities/utils";
import { RedirectModal } from "../../../Common/RedirectModal";
import { LoadingWrapper } from "../../../Settings/styled";
import { ContentSections } from "../../../styled";
import { ActionsHeader } from "../styled";
import { DataDetailsComponentSections } from "./DataDetailsComponentSections";
import ReportActionsSections from "./ReportActionsSections";
import { SectionsList } from "./SectionsList";
import { SectionsWrapper, WrappedRefreshButton } from "./styled";

export const CreateReportSections = () => {
  const { t, i18n } = useTranslation();
  const { userData, userToken } = useLoggedUser();
  const { postMessage } = useIntercom();

  const {
    reportCreation,
    scheduleDate,
    setScheduleDate,
    reportType,
    initPrefix,
    title,
    setTitle,
    uploadedScheduleDate,
    isReportChanged,
    setUploadedData,
    savedReport,
    setSavedReport,
    setInitSavedReportData,
    type,
    attachmentId,
    isScheduleDateFromFuture,
    areTagsEmpty,
  } = useReportDataSections({ i18n });
  const dispatch = useAppDispatch();
  const isMobile = useHasMaxWidth(Breakpoints.XS);
  const { getSections, sections: loadedSections } = useSection();
  const [report, setReport] = useState<Report>(null);
  const { userName: authorName, isLoading: isLoadingAuthor } = useUser(report?.author);
  const [author, setAuthor] = useState(null);
  const [isLoadingReport, setIsLoadingReport] = useState(true);
  const [isApproved, setIsApproved] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [areSectionsInProgress, setAreSectionsInProgress] = useState(true);
  const [initSections, setInitSections] = useState([]);

  const loadReport = async () => {
    const initSections = getSections(type);
    const reportId = savedReport;
    const reportData = await getDataFromBE(userToken, `/reports/${reportId}`);
    const sections = await getDataFromBE(userToken, `/reports/${reportId}/sections`);
    const finalSection = getFinalSections(initSections, sections, reportData.report_type);
    setInitSections(finalSection);
    setAreSectionsInProgress(finalSection?.some((e) => e.status !== SECTION_STATUS.APPROVED));
    setReport(reportData);
    setInitSavedReportData(reportData);
    setIsApproved(reportData.status === "Published");
    setIsLoadingReport(false);
  };

  const refreshReports = async () => {
    await loadReport();
  };

  useEffect(() => {
    dispatch(reportsActions.setPage(1));
  }, []);

  useEffect(() => {
    if (type && loadedSections && isLoadingReport) {
      if (userToken && savedReport) {
        loadReport();
      } else {
        const initSections = getSections(type);
        const finalSection = getFinalSections(initSections, null, type);
        setInitSections(finalSection);
        setIsLoadingReport(false);
        setAuthor(userData?.name);
      }
    }
  }, [userToken, savedReport, type, loadedSections, isLoadingReport, userData]);

  useEffect(() => {
    if (userData.isLoaded && !userData?.isUserNameFilled) {
      setIsOpen(true);
    }
  }, [userData]);

  return (
    <SectionsWrapper>
      <ContentSections>
        {!isMobile &&
          (isLoadingReport || isLoadingAuthor ? (
            <LoadingWrapper>
              <LoadingIndicator size="L" />
            </LoadingWrapper>
          ) : (
            <>
              <Title>
                {savedReport ? (
                  <>
                    {t("Reports.edit")} <>{title}</>
                  </>
                ) : (
                  <>
                    {t("NewReport.title")} {t(`reports.${transformNames(type)}.title`)}
                  </>
                )}
              </Title>
              <ActionsHeader>
                <ReportActionsSections
                  isChanged={isReportChanged}
                  scheduleDate={scheduleDate}
                  savedReport={savedReport}
                  reportType={reportType}
                  setTitle={setTitle}
                  initPrefix={initPrefix}
                  isApproved={isApproved}
                  isScheduleDateFromFuture={isScheduleDateFromFuture}
                  setIsApproved={setIsApproved}
                  uploadedScheduleDate={uploadedScheduleDate}
                  htmlContent={getSectionsContent(initSections)}
                  title={title}
                  reportCreation={reportCreation}
                  setSavedReport={setSavedReport}
                  setUploadedData={setUploadedData}
                  areSectionsInProgress={areSectionsInProgress}
                  areTagsEmpty={areTagsEmpty}
                />
              </ActionsHeader>
              <DataDetailsComponentSections
                isDisabled={false}
                reportCreation={reportCreation}
                setScheduleDate={setScheduleDate}
                scheduleDate={scheduleDate}
                reportType={reportType}
                savedReport={savedReport}
                attachmentId={attachmentId}
                setIsApproved={setIsApproved}
                author={author || authorName}
              />
              <>
                {savedReport && (
                  <WrappedRefreshButton>
                    <Button mode="outlined" onClick={refreshReports} icon="Reload" fitContent>
                      {t("Section.refresh")}
                    </Button>
                  </WrappedRefreshButton>
                )}
                <SectionsList savedReport={savedReport} sections={initSections} />
              </>
              <RedirectModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={t("CreateReport.FillNameModal.title")}
                buttonTitle={t("CreateReport.FillNameModal.goTo")}
                redirectTo={() => postMessage(IntercomTopic.REDIRECT, AppsEnum.Settings)}
              >
                <Text>{t("CreateReport.FillNameModal.description")}</Text>
              </RedirectModal>
            </>
          ))}
        {isMobile && (
          <LoadingWrapper>
            <Text>{t("Reports.mobileNotSupportedMessage")}</Text>
          </LoadingWrapper>
        )}
      </ContentSections>
    </SectionsWrapper>
  );
};
