import { createApi } from "@reduxjs/toolkit/query/react";
import { SendoutFiltersModel } from "../../models/SendoutFiltersModel";
import { SENDOUT_STATUS, Sendout, SendoutModel, SendoutType } from "../../models/SendoutModel";
import { baseQuery } from "../../utilities/baseQuery";

export interface FetchSendoutsParams {
  page?: number;
  limit?: number;
  filters?: SendoutFiltersModel;
}

export interface FetchSendoutsMetaResponse {
  meta: {
    count: number;
    limit: number;
    page: number;
  };
}

export const sendoutsApi = createApi({
  reducerPath: "sendoutsApi",
  baseQuery: baseQuery(),
  tagTypes: ["Sendouts"],
  endpoints: (builder) => ({
    getSendouts: builder.query<{ data: Sendout[] } & FetchSendoutsMetaResponse, FetchSendoutsParams>({
      query: ({ page, limit = 20, filters }) => ({
        url: "sendouts",
        params: {
          page,
          limit,
          ...filters,
        },
      }),
    }),
    postSendout: builder.mutation<Sendout, { schedule: Date; type: SendoutType; item_ids: string[] }>({
      query: (sendout) => ({
        url: "sendouts",
        method: "POST",
        body: sendout,
      }),
    }),
    getSendout: builder.query<Sendout, { sendoutId: string }>({
      query: ({ sendoutId }) => `sendouts/${sendoutId}`,
    }),
    deleteSendout: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `sendouts/${id}`,
        method: "DELETE",
      }),
    }),
    postSendoutAddItems: builder.mutation<Sendout, { sendoutId: string; itemIds: string[] }>({
      query: ({ sendoutId, itemIds }) => ({
        url: `sendouts/${sendoutId}/add-items`,
        method: "POST",
        body: {
          item_ids: itemIds,
        },
      }),
    }),
    deleteSendoutItem: builder.mutation<void, { sendoutId: string; itemId: string }>({
      query: ({ sendoutId, itemId }) => ({
        url: `sendouts/${sendoutId}/items/${itemId}`,
        method: "DELETE",
      }),
    }),
    publishSendout: builder.mutation<SendoutModel, { sendoutId: string; schedule: Date; type: SendoutType }>({
      query: ({ sendoutId, schedule, type }) => ({
        url: `publish/sendouts/${sendoutId}`,
        method: "POST",
        body: {
          schedule,
          type,
        },
      }),
    }),
    updateSendout: builder.mutation<Sendout, { sendoutId: string; status: SENDOUT_STATUS; schedule?: Date; type?: SendoutType }>({
      query: ({ sendoutId, status, schedule, type }) => ({
        url: `sendouts/${sendoutId}`,
        method: "PATCH",
        body: {
          status,
          schedule,
          type,
        },
      }),
    }),
  }),
});

export const {
  useLazyGetSendoutsQuery,
  usePostSendoutMutation,
  useGetSendoutQuery,
  usePostSendoutAddItemsMutation,
  useDeleteSendoutItemMutation,
  usePublishSendoutMutation,
  useDeleteSendoutMutation,
  useUpdateSendoutMutation,
} = sendoutsApi;
