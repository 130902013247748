import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";
import { LANGUAGE_KEY } from "../../constants/generalSettings";

export const selectUserToken = ({ user: { userToken } }: IRootState) => userToken;

export const selectUserId = ({ user: { userId } }: IRootState) => userId;

export const selectUserData = ({ user: { data } }: IRootState) => data;

export const selectPreferences = ({ user: { preferences } }: IRootState) => preferences;

export const selectLanguage = createSelector(selectPreferences, (preferences) => preferences && preferences.hasOwnProperty(LANGUAGE_KEY) ? preferences[LANGUAGE_KEY] as string : "en");

export const selectUser = (id: string) => createSelector([({ user: { byId } }: IRootState) => byId], (records) => (id ? records[id] : undefined));
