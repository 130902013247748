import { Toast } from "@secuis/ccp-react-components";
import React, { ReactNode, createContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Notification, NotificationMessage, NotificationTypes } from "./NotificationMessage";
import { ToastContainer, ToastWrapper } from "./Notifications.styles";

interface ChildrenPropsModel {
  children?: ReactNode | ReactNode[];
}

interface NotificationsObject {
  toasts: NotificationMessage[];
  toast: (message: NotificationMessage) => void;
}

export const NotificationsContext = createContext<NotificationsObject>({} as NotificationsObject);

export const NotificationsProvider = ({ children }: ChildrenPropsModel) => {
  const { t } = useTranslation();
  const [toasts, setToasts] = useState<Notification[]>([]);
  const counter = useRef(0);

  function toast(toast: NotificationMessage) {
    counter.current++;
    setToasts((prev: any) => [...prev, { ...toast, id: counter.current, toBeAdded: true, toBeRemoved: false }]);
    setTimeout(() => {
      setToasts((prev) =>
        prev.map((x: any) => {
          if (x.id === counter.current) {
            x.toBeAdded = false;
          }
          return x;
        })
      );
    }, 100);
  }

  const value = React.useMemo(
    () => ({
      toasts,
      toast,
    }),
    [toasts]
  );

  function handleOnCloseNotification(message: Notification) {
    setToasts((prev) =>
      prev.map((x: any) => {
        if (x.id === message.id) {
          x.toBeRemoved = true;
        }
        return x;
      })
    );
    setTimeout(() => {
      setToasts((prev) => prev.filter((x) => x !== message));
    }, 100);
  }

  function getDissolveTimer(type: NotificationTypes) {
    if (type === NotificationTypes.Error) {
      return;
    }
    return 3000;
  }

  function handleEmptyTitle(type: NotificationTypes) {
    switch (type) {
      case NotificationTypes.Confirmation:
        return t("SnackBar.label.Success");
      case NotificationTypes.Warning:
        return t("SnackBar.label.Warning");
      case NotificationTypes.Error:
        return t("SnackBar.label.Error");
      case NotificationTypes.Information:
      default:
        return t("SnackBar.label.Information");
    }
  }

  return (
    <NotificationsContext.Provider value={value}>
      {children}
      {toasts.length > 0 && (
        <ToastContainer>
          {toasts.map((x) => (
            <ToastWrapper key={x.id} toBeAdded={x.toBeAdded} toBeRemoved={x.toBeRemoved}>
              <Toast
                id={x.id}
                icon={x.icon}
                title={x.title ?? handleEmptyTitle(x.type)}
                message={x.message}
                type={x.type}
                onClose={() => {
                  handleOnCloseNotification(x);
                }}
                actions={x.actions}
                dissolveIn={getDissolveTimer(x.type)}
              />
            </ToastWrapper>
          ))}
        </ToastContainer>
      )}
    </NotificationsContext.Provider>
  );
};
