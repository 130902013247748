import { Modal } from "@secuis/ccp-react-components";
import React, { FC } from "react";
import { Report } from "../../../models/ReportModel";
import { ShareComponent } from "../../Common/ShareComponent";
import { Wrapper } from "../Create/Preview/PreviewModal.styles";
import { WrappedPreview } from "./WrappedPreview";

interface PreviewModalForReportListProps {
  showPreview: boolean;
  setShowPreview: (value: boolean) => void;
  report: Report;
}

export const PreviewModalForReportList: FC<PreviewModalForReportListProps> = ({ showPreview, setShowPreview, report }) => {
  return (
    <Wrapper>
      <Modal
        isOpen={showPreview}
        size="L"
        title={<ShareComponent id={report?.report_id} type="report" />}
        onClose={() => setShowPreview(false)}
        data-testid="PreviewModalForReportList"
      >
        <WrappedPreview report={report} isFromReportList={false} />
      </Modal>
    </Wrapper>
  );
};
