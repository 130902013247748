import { CountIndicator, Spacing } from "@secuis/ccp-react-components";
import React, { useMemo } from "react";
import { useFilters } from "../../../../hooks/useFilters";
import { useAppSelector } from "../../../../store";
import { selectBriefsFilters } from "../../../../store/briefs/BriefsSelectors";
import { selectItemsFilters } from "../../../../store/items/ItemsSelectors";
import { selectSendoutsFilters } from "../../../../store/sendouts/SendoutsSelectors";
import { selectToolbar } from "../../../../store/toolbar/ToolbarSelectors";

export const FiltersUsedIndicator = () => {
  const itemsFilters = useAppSelector(selectItemsFilters);
  const briefFilters = useAppSelector(selectBriefsFilters);
  const sendoutsFilters = useAppSelector(selectSendoutsFilters);
  const { filters: reportsFilters } = useFilters();
  const { storeType } = useAppSelector(selectToolbar);

  const filtersIndicator = useMemo(
    () => ({
      items: itemsFilters,
      reports: reportsFilters,
      briefs: briefFilters,
      sendouts: sendoutsFilters,
    }),
    [briefFilters, itemsFilters, sendoutsFilters, reportsFilters]
  );

  const indicatorValue = useMemo(
    () =>
      Object.keys(filtersIndicator[storeType]).filter((key) => {
        return (
          (typeof filtersIndicator[storeType][key] === "boolean" && filtersIndicator[storeType][key]) ||
          (typeof filtersIndicator[storeType][key] === "string" && filtersIndicator[storeType][key] !== "" && key !== "end_date") ||
          (typeof filtersIndicator[storeType][key] === "object" && filtersIndicator[storeType][key].length > 0)
        );
      })?.length ?? 0,
    [filtersIndicator, storeType]
  );

  return indicatorValue > 0 ? <CountIndicator count={indicatorValue} position="absolute" left={Spacing.XS} /> : null;
};
