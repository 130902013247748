import { UserInteraction } from "./BriefModel";
import { Section } from "./SectionModel";

export enum REPORT_STATUS {
  DRAFT = "Draft",
  PUBLISHED = "Published",
  DELIVERED = "Delivered",
}

export type Report = {
  approval_user: string;
  attachment_ids: string[];
  author: string;
  created: string;
  deleted: boolean;
  last_updated_user: string;
  report_date: string;
  report_id: string;
  user_interaction: UserInteraction;
  report_type: string;
  schedule: string;
  status: string;
  tags: string[];
  title: string;
  updated: string;
  sections?: Section[];
};

export type Reports = {
  filteredReports: Report[];
  categorizedDates: {};
};

export type FetchStatus = "pending" | "fulfilled" | "rejected" | "idle";

export enum REPORT_TYPES {
  DailyGlobal = "Global Daily",
  WeeklyGlobal = "Global Weekly",
}

export type ReportType = keyof typeof REPORT_TYPES;
