import { Breakpoints, Palette, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: ${Spacing.M}px;
  white-space: pre-line;
  @media (max-width: ${Breakpoints.XS}) {
    grid-template-columns: minmax(0, 1fr);
  }
`;

export const WrapperTopSections = styled(Stack)`
  gap: ${Spacing.M}px;
  padding-bottom: ${Spacing.M}px;
  display: flex;
  flex: 1;
  position: sticky;
  top: 0;
  background-color: ${Palette.Navy700};
  z-index: 1000;
  flex-direction: column;

  @media (max-width: ${Breakpoints.XS}) {
    gap: ${Spacing.XS}px;
    justify-content: space-between;
  }
`;

export const DateAndLocationWrapper = styled(Stack).attrs({
  direction: "column",
  gap: "M",
  pr: "XS",
})`
  max-width: 50%;

  @media (max-width: ${Breakpoints.XS}) {
    max-width: 100%;
    padding-right: 0px;
  }
`;

export const FixedDetailsColumn = styled(Stack).attrs({
  direction: "column",
  gap: "M",
})<{ $topHeight: number }>`
  position: sticky;
  top: ${({ $topHeight }) => ($topHeight ? `${$topHeight}px` : "155px")};
`;

export const WrapperLocation = styled(Stack).attrs({
  direction: "column",
  gap: "XS",
})`
  min-width: 0;
`;

export const TextBreak = styled(Text).attrs({
  bold: true,
})`
  word-break: break-all;
`;

export const CenteredImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const ImageWrapper = styled(Stack)`
  height: 300px;
`;
