import { Box, ButtonCompact, EmptyState, LoadingIndicator, Stack, Title } from "@secuis/ccp-react-components";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import { itemsActions } from "../../store/items/ItemsSlice";
import { useLazyGetSendoutsQuery } from "../../store/sendouts/SendoutsApi";
import {
  selectSendouts,
  selectSendoutsFilters,
  selectSendoutsPage,
  selectSendoutsShowMore,
  selectSendoutsStatus,
} from "../../store/sendouts/SendoutsSelectors";
import { sendoutsActions } from "../../store/sendouts/SendoutsSlice";
import { selectToolbar } from "../../store/toolbar/ToolbarSelectors";
import { toolbarActions } from "../../store/toolbar/ToolbarSlice";
import { CenteredWrapper, ItemsWrapper, LoadingWrapper } from "../Items/List/styled";
import ItemPreviewModal from "../Items/Preview/ItemPreviewModal";
import { ListContainer } from "../Reports/List/ReportsList.styles";
import { Sendout } from "./Sendout";
import { SendoutPreviewModal } from "./SendoutPreviewModal";

export const SendoutsList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { sendoutId } = useParams();
  const sendoutsList = useAppSelector(selectSendouts);
  const sendoutsStatus = useAppSelector(selectSendoutsStatus);
  const showMore = useAppSelector(selectSendoutsShowMore);
  const page = useAppSelector(selectSendoutsPage);
  const filters = useAppSelector(selectSendoutsFilters);
  const { isOpen } = useAppSelector(selectToolbar);
  const [getSendouts] = useLazyGetSendoutsQuery();
  const { state } = useLocation();
  const { pathname } = useLocation();

  useEffect(() => {
    return () => {
      sendoutId && !isOpen && dispatch(itemsActions.emptyCollection());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendoutId, isOpen]);

  const fetchSendouts = useCallback(
    (page: number) => {
      getSendouts({ page, filters });
    },
    [filters, getSendouts]
  );

  useEffect(() => {
    dispatch(sendoutsActions.setEditedId(null));
    dispatch(itemsActions.emptyCollection());
    dispatch(
      toolbarActions.setToolbar({
        visible: true,
        contentType: "filters",
        storeType: "sendouts",
        isOpen: false,
      })
    );
  }, [dispatch, state?.openToolbar, pathname]);

  useEffect(() => {
    fetchSendouts(1);
  }, []);

  useEffect(() => {
    if (sendoutId) {
      dispatch(
        toolbarActions.setToolbar({
          visible: true,
          contentType: "collection",
          storeType: "sendouts",
          isOpen: true,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendoutId]);

  return (
    <>
      <ItemsWrapper>
        <Box mb="S">
          <Title>{t("Sendouts.list.Title")}</Title>
        </Box>
        <Stack>
          <ButtonCompact mode="outlined" onClick={() => fetchSendouts(1)} icon="Reload">
            {t("reports.list.refresh")}
          </ButtonCompact>
        </Stack>
        {sendoutsStatus === "fulfilled" && sendoutsList.length > 0 && (
          <ListContainer id="scrollableSendoutsDiv" mt="XS">
            <InfiniteScroll
              dataLength={sendoutsList.length}
              next={() => fetchSendouts(page + 1)}
              hasMore={showMore}
              loader={
                <LoadingWrapper>
                  <LoadingIndicator size="L" />
                </LoadingWrapper>
              }
              scrollableTarget="scrollableSendoutsDiv"
            >
              {sendoutsList.map((sendout, index) => (
                <Sendout key={sendout.id} sendoutData={sendout} last={sendoutsList.length - 1 === index} />
              ))}
            </InfiniteScroll>
          </ListContainer>
        )}
        {sendoutsStatus === "pending" && (
          <CenteredWrapper>
            <LoadingIndicator size="L" />
          </CenteredWrapper>
        )}
        {sendoutsStatus === "fulfilled" && !sendoutsList.length && (
          <CenteredWrapper>
            <EmptyState icon="Risk" title={t("Items.list.emptyState")} />
          </CenteredWrapper>
        )}
        {sendoutsStatus === "rejected" && !sendoutsList.length && (
          <CenteredWrapper>
            <EmptyState icon="Warning" title={t("Items.list.errorState")} />
          </CenteredWrapper>
        )}
        <ItemPreviewModal isBrief />
      </ItemsWrapper>
      <SendoutPreviewModal />
    </>
  );
};
