import { useEffect, useMemo } from "react";
import { METADATA_URl_PATHS } from "../../models/ItemMetadataModel";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectUserToken } from "../user/UserSelectors";
import { useLazyGetItemQuery, useLazyGetMetadataQuery } from "./ItemsApi";
import { selectItemPreview, selectMetadata } from "./ItemsSelectors";
import { itemsActions } from "./ItemsSlice";
import { Item } from "../../models/ItemModel";

export const useItemOrganizationSpecific = (item: Item) => {
  const { organizations } = useAppSelector(selectMetadata);
  const organizationSpecifics = item.organization_specific_ids?.length && organizations.filter(({ id }) => item.organization_specific_ids.includes(id));

  return {
    organizationSpecifics,
  };
};

export const usePreloadMetadata = () => {
  const userToken = useAppSelector(selectUserToken);
  const [fetchMetadata] = useLazyGetMetadataQuery();

  useEffect(() => {
    if (userToken) {
      METADATA_URl_PATHS.forEach((propertyPath) => {
        fetchMetadata(propertyPath);
      });
    }
  }, [userToken, fetchMetadata]);
};

export const useItemPreview = () => {
  const dispatch = useAppDispatch();
  const { item: stateItem, itemId } = useAppSelector(selectItemPreview);
  const [fetchItem, { data: apiItem, isLoading, isError }] = useLazyGetItemQuery();

  const clearPreview = () => {
    dispatch(itemsActions.clearPreview());
  };

  const item = useMemo(() => {
    if (itemId) {
      // item can be put in store when editing
      if (stateItem) {
        return stateItem;
      }
      // otherwise we need to fetch it,
      // but we need to ignore cashed item from previous fetch (on the list view you can preview multiple items one after another)
      if (apiItem?.id === itemId) {
        return apiItem;
      }
    }
    return undefined;
  }, [itemId, stateItem, apiItem]);

  useEffect(() => {
    if (!stateItem && itemId) {
      fetchItem(itemId);
    }
  }, [stateItem, itemId, fetchItem]);

  return {
    item,
    itemId,
    isLoading,
    isError,
    clearPreview,
  };
};
