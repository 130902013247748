import { Badge, Box, Breakpoints, ButtonCompact, GraphIcon, Headline, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { Brief as BriefModel } from "../../models/BriefModel";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectBriefs } from "../../store/briefs/BriefsSelectors";
import { itemsActions } from "../../store/items/ItemsSlice";
import { useLoggedUser } from "../../store/user/LoggedUserHooks";
import { useSetInteractionsMutation } from "../../store/user/UserApi";
import { getDateOfEvent } from "../../utilities/Item.helpers";
import { getTranslatedTag } from "../../utilities/tagsUtils";
import { Parameter } from "../Items/List/Parameter";
import { Buttons, ItemContainer, ItemOthers, ItemTags, ParametersWrapper } from "../Items/List/styled";
import { BriefParameters, TagParameter } from "./Brief.styles";

interface BriefProps {
  briefData: BriefModel;
}

export const Brief = ({ briefData }: BriefProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { categorizedDates: dates } = useAppSelector(selectBriefs);
  const { userId } = useLoggedUser();
  const isMobile = useHasMaxWidth(Breakpoints.XS);
  const [setInteractions] = useSetInteractionsMutation();

  const onBriefOpen = () => {
    setInteractions({ userId, entity_id: briefData.id, entity_type: "item", viewed: true, flagged: false });
    dispatch(itemsActions.setPreview({ itemId: briefData.id }));
  };

  return (
    <>
      {dates[briefData.id] && (
        <Stack justifyContent="flex-end" mr="XS" data-testid="dateTag">
          <Text data-testid={`ReportDateSeparator-${dates[briefData.id]}`} micro uppercase color="secondary">
            {t(dates[briefData.id])}
          </Text>
        </Stack>
      )}
      <ItemContainer selected={false}>
        <Stack alignItems="center" gap="XS" mb="XS">
          {!briefData.user_interaction.viewed && (
            <Stack alignItems="center">
              <GraphIcon color="total" variant="GraphTotal" />
            </Stack>
          )}
          <Headline>{briefData.title}</Headline>
        </Stack>
        <BriefParameters>
          <Stack direction="row">
            <Parameter label={t("Item.dateOfEvent")} icon={!isMobile ? "Calendar" : undefined} medium truncate={false}>
              {getDateOfEvent(briefData)}
            </Parameter>
            <Parameter
              label={t("Items.location")}
              icon={!isMobile ? "Location" : undefined}
              truncate={false}
            >{`${briefData.settlement}, ${briefData.country}`}</Parameter>
            <Parameter label={t("Item.threatLevel")} icon={!isMobile ? "Show" : undefined} medium truncate={false}>
              {t(`Brief.threatLevel.${briefData.threat_level}`)}
            </Parameter>
          </Stack>
          <TagParameter>
            <ItemTags>
              {[...new Set(briefData.threat_risk_categories)].map((tag) => (
                <Badge key={`${briefData.id}-${tag}`} label={getTranslatedTag(tag)} />
              ))}
            </ItemTags>
          </TagParameter>
        </BriefParameters>
        <ItemOthers>
          <ParametersWrapper>
            <Stack flex="1" direction="column">
              <Box mt="XXS" mr="L">
                <Text small={isMobile} truncate>
                  {briefData.details}
                </Text>
              </Box>
            </Stack>
          </ParametersWrapper>
          <Buttons>
            <ButtonCompact mode="contained" color="accent" onClick={onBriefOpen}>
              {t("Brief.open")}
            </ButtonCompact>
          </Buttons>
        </ItemOthers>
      </ItemContainer>
    </>
  );
};
