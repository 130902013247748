import { DropdownCheckbox } from "@secuis/ccp-react-components";
import React, { useEffect, useState } from "react";
import { useUserData } from "../../hooks/useUserData";
import { useAppSelector } from "../../store";
import { useUser } from "../../store/user/UserHooks";
import { selectPreferences } from "../../store/user/UserSelectors";
import { getTranslatedTag } from "../../utilities/tagsUtils";
import { SettingsValue } from "./Settings";

export const DropdownSettings = ({ title }) => {
  const [dropdownValues, setDropdownValues] = useState([]);
  const [options, setOptions] = useState([]);
  const preferences = useAppSelector(selectPreferences);
  const { userId } = useUserData();
  const { updatePreferences } = useUser(userId);

  const findArrayChanges = (
    oldArray,
    newArray
  ): {
    key: string;
    ret: SettingsValue;
  } => {
    const added = newArray.find((item) => !oldArray.includes(item));
    const removed = oldArray.find((item) => !newArray.includes(item));

    let ret = {};
    let key = null;

    if (added !== undefined) {
      ret = { value: "true", type: "bool" };
      key = added;
    } else if (removed !== undefined) {
      ret = { value: "false", type: "bool" };
      key = removed;
    }
    return { key, ret };
  };

  useEffect(() => {
    const keys = Object.entries(preferences)
      .filter((elem) => {
        return elem[0].includes(`ric.bronze.tags.${title}`);
      })
      .map((elem) => {
        return {
          value: elem[0],
          title: getTranslatedTag(elem[0].replace(`ric.bronze.tags.${title}.`, "")),
          init: elem[1],
        };
      });

    setOptions(keys);
    setDropdownValues(keys.filter((elem) => elem.init).map((elem) => elem.value));
  }, []);

  return (
    <DropdownCheckbox
      data-testid={`dropdown-settings-${title}`}
      label={getTranslatedTag(title, "region")}
      placeholder={getTranslatedTag(title, "region")}
      onChange={(values: string[]) => {
        const { ret, key } = findArrayChanges(dropdownValues, values);

        setDropdownValues(values);
        key && updatePreferences(key, ret, ret.value === "true");
      }}
      options={options}
      sheetCancelLabel="Cancel"
      values={dropdownValues}
    />
  );
};
