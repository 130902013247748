import { Box, Breakpoints, ButtonCompact, LoadingIndicator, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useFilters } from "../../../hooks/useFilters";
import { useUserData } from "../../../hooks/useUserData";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectDrafts } from "../../../store/drafts/DraftsSelectors";
import { fetchDraftsThunk } from "../../../store/drafts/DraftsThunkActions";
import { selectPage, selectReports, selectReportsStatus, selectShowMore } from "../../../store/reports/ReportsSelectors";
import { reportsActions } from "../../../store/reports/ReportsSlice";
import { FetchReportsParams, fetchReportsThunk } from "../../../store/reports/ReportsThunkActions";
import { selectLanguage } from "../../../store/user/UserSelectors";
import { getReport } from "../../../utilities/userUtils";
import { LoadingWrapper } from "../../Settings/styled";
import { PreviewReport } from "../Preview/PreviewReport";
import { ReportPreviewFromEmail } from "../Preview/ReportPreviewFromEmail";
import { DraftsList } from "./Drafts/DraftsList";
import { ReportElem } from "./ReportElem";
import { ReportElemForCreators } from "./ReportElemForCreators";
import { ListContainer, ListWrapper, OngoingReports } from "./ReportsList.styles";

interface ReportsListProps {
  couldCreateReport: boolean;
}

export const ReportsList: FC<ReportsListProps> = ({ couldCreateReport }) => {
  const { t } = useTranslation();
  const { userToken, userId } = useUserData();
  const lang = useAppSelector(selectLanguage);
  const { filteredReports } = useAppSelector(selectReports);
  const draftReports = useAppSelector(selectDrafts);
  const loading = useAppSelector(selectReportsStatus);
  const page = useAppSelector(selectPage);
  const showMore = useAppSelector(selectShowMore);
  const dispatch = useAppDispatch();
  const { url } = useFilters();
  const isMobile = useHasMaxWidth(Breakpoints.L);

  const [selectedReportId, setSelectedReportId] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [draftHeight, setDraftHeight] = useState(64);
  const draftRef = useRef();
  const dateType = "schedule";

  const fetchReports = useCallback(
    async (force = false) => {
      if (!userToken || !userId || (page > 1 && !force) || url === "") {
        return;
      }

      dispatch(reportsActions.setPage(1));

      const parameters: FetchReportsParams & { couldCreateReport: boolean } = {
        userToken,
        page: 1,
        url,
        lang,
        couldCreateReport,
      };

      dispatch(fetchReportsThunk(parameters));
    },
    [couldCreateReport, userToken, userId, url, page]
  );

  const fetchReport = useCallback(async () => {
    if (!userToken || !userId || !selectedReportId) {
      return;
    }
    try {
      setIsLoading(true);
      const report = await getReport(userToken, selectedReportId, lang);
      setSelectedReport(report);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, [lang, selectedReportId, userId, userToken]);

  useEffect(() => {
    couldCreateReport !== null && fetchReports();
  }, [couldCreateReport, dateType, userId, userToken, url]);

  useEffect(() => {
    !couldCreateReport && selectedReportId !== null && fetchReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couldCreateReport, selectedReportId]);

  const fetchMoreData = useCallback(async () => {
    const parameters: FetchReportsParams & { couldCreateReport: boolean } = {
      userToken,
      page: page + 1,
      url,
      lang,
      couldCreateReport,
    };
    dispatch(fetchReportsThunk(parameters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couldCreateReport, filteredReports, userToken, page, url]);

  const handleRefreshList = useCallback(() => {
    setSelectedReportId(null);
    setSelectedReport(null);
    fetchReports(true);
    dispatch(fetchDraftsThunk({ userToken }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couldCreateReport, userId, userToken, url, page]);

  useEffect(() => {
    if (!draftRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver((node) => {
      setDraftHeight(node[0].contentRect.height);
    });
    resizeObserver.observe(draftRef.current);
    return () => resizeObserver.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftRef.current]);

  return (
    <>
      <OngoingReports>
        {(filteredReports.length > 0 || draftReports.length > 0) && (
          <Box>
            <ButtonCompact mode="outlined" onClick={handleRefreshList} icon="Reload">
              {t("reports.list.refresh")}
            </ButtonCompact>
          </Box>
        )}
        {couldCreateReport && <DraftsList ref={draftRef} />}
      </OngoingReports>

      {(loading === "pending" || couldCreateReport === null) && (
        <LoadingWrapper>
          <LoadingIndicator size="L" />
        </LoadingWrapper>
      )}

      <ListWrapper couldCreateReport={couldCreateReport}>
        {loading === "fulfilled" && couldCreateReport !== null && (
          <>
            <ListContainer id="scrollableDiv" $draftHeight={draftHeight}>
              <InfiniteScroll
                dataLength={filteredReports.length || 0}
                next={fetchMoreData}
                hasMore={showMore}
                loader={
                  <LoadingWrapper>
                    <LoadingIndicator size="L" />
                  </LoadingWrapper>
                }
                scrollableTarget="scrollableDiv"
              >
                {filteredReports.length === 0 ? (
                  <LoadingWrapper>
                    <Text> {t("Reports.emptyList")}</Text>
                  </LoadingWrapper>
                ) : (
                  filteredReports.map((elem) =>
                    couldCreateReport ? (
                      <ReportElemForCreators
                        data-testid="report-elem-creator"
                        key={`report-${elem.report_id}`}
                        report={elem}
                        setSelectedReportId={setSelectedReportId}
                        selectedReportId={selectedReportId}
                      />
                    ) : (
                      <ReportElem
                        data-testid="report-elem"
                        key={`report-${elem.report_id}`}
                        report={elem}
                        setSelectedReportId={setSelectedReportId}
                        selectedReportId={selectedReportId}
                      />
                    )
                  )
                )}
              </InfiniteScroll>
            </ListContainer>
            {!couldCreateReport && !isMobile && <PreviewReport report={selectedReport} isLoading={isLoading} />}
          </>
        )}
        <ReportPreviewFromEmail setSelectedReportId={setSelectedReportId} />
      </ListWrapper>
    </>
  );
};
