import { addMonths, addWeeks, endOfMonth, endOfWeek, format, isSameDay, startOfMonth, startOfTomorrow, startOfWeek, startOfYesterday } from "date-fns";
import { getLocal } from "../constants/generalSettings";

interface ReportItem {
  id: string;
  type:
  | "Report.today"
  | "Report.yesterday"
  | "Report.thisMonth"
  | "Report.older"
  | "Report.inFuture"
  | "Report.tomorrow"
  | "Report.thisWeek"
  | "Report.nextWeek"
  | "Report.nextMonth";
}
interface CategorizedDates {
  today?: ReportItem;
  yesterday?: ReportItem;
  thisMonth?: ReportItem;
  older?: ReportItem;
  inFuture?: ReportItem;
  tomorrow?: ReportItem;
  thisWeek?: ReportItem;
  nextWeek?: ReportItem;
  nextMonth?: ReportItem;
}

export const categorizeDates = (dates, dateType, dateId = "report_id") => {
  const categoryTitles = {};
  const c = {
    today: new Date(),
    yesterday: startOfYesterday(),
    thisMonth: {
      start: startOfMonth(new Date()),
      end: endOfMonth(new Date()),
    },
    nextMonth: {
      start: startOfMonth(addMonths(new Date(), 1)),
      end: endOfMonth(addMonths(new Date(), 1)),
    },
    tomorrow: startOfTomorrow(),
    thisWeek: {
      start: startOfWeek(new Date(), { weekStartsOn: 1 }),
      end: endOfWeek(new Date(), { weekStartsOn: 1 }),
    },
    nextWeek: {
      start: startOfWeek(addWeeks(new Date(), 1), { weekStartsOn: 1 }),
      end: endOfWeek(addWeeks(new Date(), 1), { weekStartsOn: 1 }),
    },
  };
  let categorizedDates: CategorizedDates = {};

  const datesRules = (elem) => {
    const date = new Date(elem[dateType]);

    return {
      inFuture: date > c.nextMonth.end,
      nextMonth: date >= c.nextMonth.start && date <= c.nextMonth.end,
      nextWeek: date >= c.nextWeek.start && date <= c.nextWeek.end,
      tomorrow: date.toDateString() === c.tomorrow.toDateString(),
      today: date.toDateString() === c.today.toDateString(),
      yesterday: date.toDateString() === c.yesterday.toDateString(),
      thisWeek: date >= c.today && date <= c.thisWeek.end,
      thisMonth: date >= c.today && date <= c.thisMonth.end,
      older: true,
    };
  };

  dates.forEach((elem) => {
    const dRules = datesRules(elem);
    const dateKey = Object.keys(dRules).find((item) => !!dRules[item]);
    categorizedDates = {
      [dateKey]: { id: elem[dateId], type: `Reports.${dateKey}` },
      ...categorizedDates,
    };
  });

  Object.keys(categorizedDates).forEach((key) => {
    const e = categorizedDates[key];
    categoryTitles[e.id] = e.type;
  });

  return categoryTitles;
};

export const isDateFromFuture = (inputDate, currentDate = new Date()) => {
  const futureDate = new Date(currentDate.getTime() + 5 * 60000);
  return inputDate >= futureDate;
};

export const showEventDate = (startDate: string, endDate: string, dateFormat: string = "yyyy-MM-dd") => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  return isSameDay(start, end) ? format(start, dateFormat) : `${format(start, dateFormat)} - ${format(end, dateFormat)}`;
};

export const getLocalDate = (date: string | Date, language: string, withTime: boolean = true, withDayOfWeek = true) => {
  const newDate = typeof date === "string" ? new Date(date) : date;

  return date
    ? `${format(newDate, withDayOfWeek ? "iii, d MMM yyyy" : "d MMM yyyy", { locale: getLocal(language) })} ${withTime ? `\u00A0 \u00A0 ${format(newDate, "HH:mm", { locale: getLocal(language) })}` : ""
    }`
    : "-";
};

export const getDateWithoutTimezone = (date: string) => {
  if (!date) {
    return;
  }
  return new Date(date.slice(0, -1));
};
