import { EmptyState, LoadingIndicator, Stack } from "@secuis/ccp-react-components";
import React, { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useItemPreview } from "../../../store/items/ItemsHooks";
import { ShareComponent } from "../../Common/ShareComponent";
import ItemPreview from "./ItemPreview";
import ModalExtraLarge from "./ModalExtraLarge";

interface ItemPreviewModalProps {
  isBrief?: boolean;
}

const ItemPreviewModal: FC<ItemPreviewModalProps> = ({ isBrief }) => {
  const { t } = useTranslation();
  const { item, itemId, isLoading, isError, clearPreview } = useItemPreview();

  const isOpen = useMemo(() => !!itemId, [itemId]);

  useEffect(() => {
    return () => {
      clearPreview();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalExtraLarge isOpen={isOpen} onClose={clearPreview} title={<ShareComponent id={item?.id} type="brief" />}>
      {isLoading && (
        <Stack justifyContent="center" flex="1">
          <LoadingIndicator size="XL" />
        </Stack>
      )}
      {isError && (
        <Stack justifyContent="center" flex="1">
          <EmptyState title={t("errors.fetchError.title")} subtitle={t("errors.fetchError.message")} icon="WarningHigh" />
        </Stack>
      )}
      {!!item && <ItemPreview isBrief={isBrief} item={item} onClose={clearPreview} />}
    </ModalExtraLarge>
  );
};

export default ItemPreviewModal;
