import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../store";
import { itemsActions } from "../../../../store/items/ItemsSlice";
import { ConfirmationModal } from "../../../Common/ConfirmationModal";

export const HeadModal = ({ isEmptyCartModalOpen, setIsEmptyCartModalOpen }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleEmptyCart = () => {
    dispatch(itemsActions.emptyCollection());
    setIsEmptyCartModalOpen(false);
  };

  return (
    <ConfirmationModal
      isOpen={isEmptyCartModalOpen}
      setIsOpen={setIsEmptyCartModalOpen}
      action={handleEmptyCart}
      title={t("Items.collection.empty.title")}
      subtitle={t("Briefs.collection.empty.subtitle")}
      yesColor="error"
    />
  );
};
