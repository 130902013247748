import { Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

interface ChipsStackProps {
  $chipsNumber: number;
}

export const ChipsStack = styled(Stack)<ChipsStackProps>`
  margin-bottom: ${({ $chipsNumber }) => ($chipsNumber > 0 ? `${Spacing.XS}px` : `${Spacing.XXXS}px`)};
`;
