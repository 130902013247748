import { Headline, Separator, Stack, Text } from "@secuis/ccp-react-components";
import React, { FC, Fragment, PropsWithChildren } from "react";
import { Wrapper, WrapperKey, WrapperValue } from "./BulletSection.styles";

interface BulletSectionProps {
  title: string;
  value?: string;
  bulletPoints?: { title: string; content?: string }[];
}

const BulletSection: FC<PropsWithChildren<BulletSectionProps>> = ({ title, value, bulletPoints, children }) => {
  return (
    <Wrapper>
      <Stack justifyContent="space-between" alignItems="center">
        <Headline>{title}</Headline>
        <Text>{value}</Text>
      </Stack>
      <Stack direction="column">
        {bulletPoints?.map(({ title, content }) => (
          <Fragment key={title}>
            <Stack justifyContent="space-between">
              <WrapperKey>
                <Text bold>{title}</Text>
              </WrapperKey>
              <WrapperValue>
                <Text truncate title={content}>
                  {content}
                </Text>
              </WrapperValue>
            </Stack>
            <Separator />
          </Fragment>
        ))}
        {children}
      </Stack>
    </Wrapper>
  );
};

export default BulletSection;
