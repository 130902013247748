import { Box, Link, Separator } from "@secuis/ccp-react-components";
import React, { Fragment } from "react";
import { TextBreak } from "./ItemPreview.styles";

export const ItemSource = ({ source }: { source: string }) => {
  let url: string | URL;
  try {
    url = new URL(source);
  } catch (e) {
    url = source;
  }
  return (
    <Fragment key={source}>
      <Box p="XS">
        {typeof url === "object" ? (
          <Link href={url.href} target="_blank">
            {source}
          </Link>
        ) : (
          <TextBreak>{url}</TextBreak>
        )}
      </Box>
      <Separator />
    </Fragment>
  );
};
