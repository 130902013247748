import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type ToolbarState = {
  visible: boolean;
  contentType: "filters" | "collection";
  storeType: "reports" | "items" | "briefs" | "sendouts";
  isOpen: boolean;
};

export const initialState: ToolbarState = {
  visible: true,
  contentType: "filters",
  storeType: "reports",
  isOpen: false,
};

export const toolbarSlice = createSlice({
  name: 'toolbar',
  initialState,
  reducers: {
    setToolbar: (state, { payload }: PayloadAction<Partial<ToolbarState>>) => {
      if (payload?.isOpen === false) {
        state.contentType = initialState.contentType;
      } else {
        state.contentType = payload?.contentType || state.contentType;
      }
      state.visible = payload?.visible ?? state.visible;
      state.isOpen = payload?.isOpen ?? state.isOpen;
      state.storeType = payload?.storeType || state.storeType;
    },
  },
});

export const toolbarActions = toolbarSlice.actions;