import { createApi } from "@reduxjs/toolkit/query/react";
import { UserModel, UserRole } from "../../models/UserModel";
import { baseQuery } from "../../utilities/baseQuery";

export interface Interaction {
  entity_id: string;
  entity_type: 'item' | 'report';
  viewed: boolean;
  flagged: boolean;
};

export type Preference = {
  preference_key: string;
  type: string;
  value: string;
};

export interface PreferenceResponse {
  id: string;
  preference: Preference
};

export interface PreferencesResponse {
  id: string;
  preferences: Preference[]
};

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQuery(process.env.REACT_APP_MYSACCESS_BASE_URL),
  endpoints: (builder) => ({
    getUserByEmail: builder.query<UserModel, string>({
      query: (userEmail) => ({
        url: `/users/byEmail/${userEmail}`,
      }),
    }),
    getUserById: builder.query<UserModel, string>({
      query: (userId) => ({
        url: `/users/byId/${userId}`,
      }),
    }),
    getRoles: builder.query<UserRole[], string>({
      query: (userId) => ({
        url: `/authorization/records/byUserId/${userId}`,
      }),
    }),
    getPreferences: builder.query<PreferencesResponse, string>({
      query: (userId) => ({
        url: `/ric/users/${userId}/user-preferences`,
      }),
    }),
    updatePreference: builder.mutation<PreferenceResponse, { userId: string; preferenceKey: string; preferenceType: string; preferenceValue: string }>({
      query: ({ userId, preferenceKey, preferenceType, preferenceValue }) => ({
        url: `/ric/users/${userId}/user-preferences/${preferenceKey}`,
        method: "PATCH",
        body: {
          type: preferenceType,
          value: preferenceValue
        }
      }),
    }),
    setInteractions: builder.mutation<Interaction, Interaction & { userId: string }>({
      query: ({ userId, entity_id, entity_type, viewed, flagged }) => ({
        url: `/ric/users/${userId}/interactions`,
        method: "PATCH",
        body: {
          entity_id,
          entity_type,
          viewed,
          flagged,
        },
      }),
    }),
  }),
});

export const {
  useLazyGetUserByEmailQuery,
  useLazyGetUserByIdQuery,
  useLazyGetRolesQuery,
  useLazyGetPreferencesQuery,
  useSetInteractionsMutation,
  useUpdatePreferenceMutation
} = userApi;
