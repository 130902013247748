import { useCallback, useEffect, useState } from "react";
import { useSchedule } from "./useSchedule";
import { REPORT_TYPES } from "../models/ReportModel";
import { SENDOUT_TYPE } from "../models/SendoutModel";

export const useScheduleDate = (page: "reports" | "sendouts", type: string | null) => {
  const { getSchedule, schedules } = useSchedule();
  const [scheduleDate, setScheduleDate] = useState<Date | null>();

  const SCHEDULES = {
    sendouts: {
      type: (type && SENDOUT_TYPE[type] === SENDOUT_TYPE.daily) ? REPORT_TYPES.DailyGlobal : REPORT_TYPES.WeeklyGlobal,
    },
    reports: {
      type
    }
  };

  const fetchDefaultSchedule = useCallback(
    () => {
      const nextSchedule = getSchedule(SCHEDULES[page]?.type);
      if (nextSchedule) {
        setScheduleDate(nextSchedule);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type, page],
  );

  useEffect(() => {
    if (schedules && type) {
      fetchDefaultSchedule();
    }
    if (type === null) {
      setScheduleDate(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, schedules]);

  return { scheduleDate, setScheduleDate };
};