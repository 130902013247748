import { Badge, Box, ButtonCompact, Headline, Icon, Separator, Stack, Text, Title } from "@secuis/ccp-react-components";
import React, { FC, Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Item } from "../../../models/ItemModel";
import { useItemOrganizationSpecific } from "../../../store/items/ItemsHooks";
import { useUser } from "../../../store/user/UserHooks";
import { getDateOfEvent } from "../../../utilities/Item.helpers";
import { getLocalDate } from "../../../utilities/dateUtils";
import { getTranslatedTag } from "../../../utilities/tagsUtils";
import ItemMap from "../Map/ItemMap";
import BulletSection from "./BulletSection";
import { CenteredImage, DateAndLocationWrapper, FixedDetailsColumn, ImageWrapper, Wrapper, WrapperLocation, WrapperTopSections } from "./ItemPreview.styles";
import { ItemSource } from "./ItemSource";

interface ItemPreviewProps {
  item: Item;
  isBrief?: boolean;
  onClose: () => void;
}

const ItemPreview: FC<ItemPreviewProps> = ({ item, isBrief, onClose }) => {
  const { t, i18n } = useTranslation();
  const { userName: authorName } = useUser(item.added_by);
  const { userName: reviewerName } = useUser(item.reviewed_by);
  const topSection = useRef(null);
  const { organizationSpecifics } = useItemOrganizationSpecific(item);

  // some of the properties might no be filled in, therefore unnecessary comma could be rendered, hence filtering out the empty values
  const locationString = [item.settlement, item.country].filter((i) => i).join(", ");
  const coordinatesString = item.geolocation && [item.geolocation.latitude, item.geolocation.longitude].filter((i) => i).join(", ");
  const threatActors = item.threat_actors?.length ? item.threat_actors.map((title) => ({ title })) : [{ title: "-" }];
  const sources = item.source_links?.length ? item.source_links : ["-"];
  const hasCoordinates = item.geolocation && item.geolocation.longitude && item.geolocation.latitude;

  return (
    <Stack direction="column">
      <WrapperTopSections ref={topSection}>
        <Title>{item.title}</Title>
        <DateAndLocationWrapper>
          <Stack direction="row" justifyContent="space-between" flex={1}>
            <Stack direction="column" gap="XS">
              <Text micro bold uppercase color="secondary">
                {t("ItemPreview.dateOfEvent")}
              </Text>
              <Stack gap="XS" alignItems="center">
                <Icon size="L" variant="Calendar" />
                {item.start_date_of_event && (
                  <Text bold truncate>
                    {getDateOfEvent(item)}
                  </Text>
                )}
              </Stack>
            </Stack>
            <WrapperLocation>
              <Text micro bold uppercase color="secondary">
                {t("ItemPreview.location")}
              </Text>
              <Stack gap="XS" alignItems="center">
                <Icon size="L" variant="Location" />
                <Text bold title={locationString}>
                  {locationString}
                </Text>
              </Stack>
            </WrapperLocation>
          </Stack>
        </DateAndLocationWrapper>
        <Stack>
          <Separator />
        </Stack>
      </WrapperTopSections>
      <Wrapper>
        <Stack direction="column" gap="L">
          <Stack direction="column" gap="M">
            {item.image_url && (
              <ImageWrapper direction="column" gap="XXXS">
                <CenteredImage src={item.image_url} alt={item.image_source_link} title={item.image_source_link} />
                {item.image_source_link && (
                  <Stack justifyContent="flex-end">
                    <Text micro color="tertiary">
                      {item.image_source_link}
                    </Text>
                  </Stack>
                )}
              </ImageWrapper>
            )}
            {item.sub_header && <Headline color="secondary">{item.sub_header}</Headline>}
            <Stack gap="XS" flexWrap="wrap">
              {[...new Set(item.threat_risk_categories)].map((tag) => (
                <Badge key={tag} label={getTranslatedTag(tag)} />
              ))}
            </Stack>
            <Stack direction="column" gap="S">
              {item.ric_region_daily && <Headline>{getTranslatedTag(item.ric_region_daily, "region")}</Headline>}
              <Text>{item.details}</Text>
            </Stack>
            {item.assessment_or_comment && (
              <div>
                <Text color="error" as="span">
                  {t("ItemPreview.commentStart")}
                </Text>
                <Text as="span">{item.assessment_or_comment}</Text>
                <Text color="error" as="span">
                  {t("ItemPreview.commentEnd")}
                </Text>
              </div>
            )}
            {item.advisory && (
              <Stack direction="column" gap="S">
                <Text bold>{t("ItemPreview.advisory")}</Text>
                <Text>{item.advisory}</Text>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack direction="column" gap="M" flex="auto">
          {!!hasCoordinates && <ItemMap item={item} disableScroll />}
          <FixedDetailsColumn $topHeight={topSection.current?.offsetHeight}>
            {!isBrief && (
              <BulletSection
                title={t("ItemPreview.itemInfo")}
                bulletPoints={[
                  {
                    title: t("ItemPreview.addedOn"),
                    content: item.date_added ? getLocalDate(item.date_added, i18n.language) : "-",
                  },
                  {
                    title: t("ItemPreview.addedBy"),
                    content: authorName,
                  },
                  {
                    title: t("ItemPreview.reviewedBy"),
                    content: reviewerName,
                  },
                  {
                    title: t("ItemPreview.recurrence"),
                    content: item.recurrence ? t("Common.yes") : t("Common.no"),
                  },
                  {
                    title: t("ItemPreview.reminder"),
                    content: item.set_reminder ? getLocalDate(item.set_reminder, i18n.language) : t("Common.no"),
                  },
                ]}
              />
            )}
            <BulletSection
              title={t("ItemPreview.location")}
              bulletPoints={[
                {
                  title: t("ItemPreview.coordinates"),
                  content: coordinatesString || "-",
                },
                {
                  title: t("ItemPreview.country"),
                  content: item.country || "-",
                },
                {
                  title: t("ItemPreview.state"),
                  content: item.state_or_province || "-",
                },
                {
                  title: t("ItemPreview.cityLocation"),
                  content: item.settlement || "-",
                },
              ]}
            />
            <BulletSection
              title={t("ItemPreview.threatRiskLevel")}
              value={item.threat_level ? t(`Items.threatLevels.${item.threat_level}`) : "-"}
              bulletPoints={[
                {
                  title: t("ItemPreview.threatRatingSecurity"),
                  content: item.threat_rating_security ? t(`Items.threatLevels.${item.threat_rating_security}`) : "-",
                },
                {
                  title: t("ItemPreview.threatRatingOperations"),
                  content: item.threat_rating_operations ? t(`Items.threatLevels.${item.threat_rating_operations}`) : "-",
                },
                {
                  title: t("ItemPreview.threatRatingBrandAndReputation"),
                  content: item.threat_rating_brand_and_reputation ? t(`Items.threatLevels.${item.threat_rating_brand_and_reputation}`) : "-",
                },
              ]}
            />
            <BulletSection title={t("ItemPreview.threatActors")} bulletPoints={threatActors} />
            {!isBrief && (
              <BulletSection title={t("ItemPreview.visibilityForConsumers")}>
                {!organizationSpecifics?.length && !item.sector_specific?.length ? (
                  <Text bold>-</Text>
                ) : (
                  <>
                    {organizationSpecifics?.length > 0 && (
                      <>
                        <Box pt="XS">
                          <Text micro uppercase color="secondary">
                            {t("ItemPreview.visibilityForConsumersOrganizationSpecific")}
                          </Text>
                        </Box>
                        <Stack direction="column">
                          {organizationSpecifics.map((organization) => (
                            <Fragment key={organization.id}>
                              <Box p="XS">
                                <Text bold>{organization.name}</Text>
                              </Box>
                              <Separator />
                            </Fragment>
                          ))}
                        </Stack>
                      </>
                    )}
                    {item.sector_specific?.length > 0 && (
                      <>
                        <Box pt="XS">
                          <Text micro uppercase color="secondary">
                            {t("ItemPreview.visibilityForConsumersSectorSpecific")}
                          </Text>
                        </Box>
                        {item.sector_specific.map((spec) => (
                          <Fragment key={spec}>
                            <Box p="XS">
                              <Text bold>{spec}</Text>
                            </Box>
                            <Separator />
                          </Fragment>
                        ))}
                      </>
                    )}
                  </>
                )}
              </BulletSection>
            )}
            {!isBrief && (
              <BulletSection title={t("ItemPreview.sources")}>
                <Stack direction="column">
                  {sources.map((source) => {
                    return <ItemSource source={source} />;
                  })}
                </Stack>
              </BulletSection>
            )}
            <Stack justifyContent="flex-end">
              <ButtonCompact mode="contained" color="accent" onClick={onClose}>
                {t(isBrief ? "ItemPreview.closeButtonBrief" : "ItemPreview.closeButton")}
              </ButtonCompact>
            </Stack>
          </FixedDetailsColumn>
        </Stack>
      </Wrapper>
    </Stack>
  );
};

export default ItemPreview;
