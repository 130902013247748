import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ButtonText, ChipFilter, ComboBox, DropdownOption, Stack } from "@secuis/ccp-react-components";
import { ChipsStack } from "../../Reports/List/ReportActions/styled";

interface ChipsDropdownProps {
  values: string[];
  setValues: (values: string[]) => void;
  source: DropdownOption[];
  placeholder: string;
}

export const ChipsDropdown: FC<ChipsDropdownProps> = ({ values = [], setValues, source, placeholder }) => {
  const { t } = useTranslation();

  const onRemoveHandle = (e, value: string) => {
    if (e.target?.toString()?.toLowerCase()?.includes("svg")) {
      const newList = values.filter((v) => v !== value);
      setValues(newList);
    }
  };

  const onChangeHandle = (value: string) => {
    setValues(Array.from(new Set([...values, value])));
  };

  const onClickHandle = () => {
    setValues([]);
  };

  return (
    <>
      <ComboBox placeholder={placeholder} data-testid="typeDropdown" options={source} onChange={onChangeHandle} value="" />
      {values?.length > 0 && (
        <ChipsStack $chipsNumber={values.length} gap="XS" flexWrap="wrap" data-testid="stack-chips">
          {values.map((value) => (
            <ChipFilter key={value} label={source.find((option) => option.value === value)?.title} onRemove={(e) => onRemoveHandle(e, value)} />
          ))}
          <Stack alignItems="center">
            <ButtonText type="button" color="accent" onClick={onClickHandle}>
              {t("chips.clearAll")}
            </ButtonText>
          </Stack>
        </ChipsStack>
      )}
    </>
  );
};
