export const postReport = async (accessToken: string, endpoint: string, content: object | string) => {
  const requestUrl = `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`;

  const response = await fetch(requestUrl, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(content),
  });
  if (response.status === 401) {
    console.log("Unathorized access. Access token may be stale. Status: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error("Unauthorized access"));
  }

  if (response.status === 409) {
    console.log("report with this schedule date already exists");
    return Promise.reject(Error("report with this schedule date already exists"));
  }
  if (response.status === 204) {
    return Promise.resolve(true);
  }
  if (response.status !== 201) {
    console.log("An error was returned, code: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error("action failed"));
  }

  return await response.json();
};

export const updateSection = async (accessToken: string, reportId: string, sectionId: string, content: object | string): Promise<any> => {
  const endpoint = `/reports/${reportId}/sections/${sectionId}`;

  const response = await updateReport(accessToken, endpoint, content);
  return response;
};

export const updateReport = async (accessToken: string, endpoint: string, content: object | string, method = "PATCH", returnBody = true) => {
  const requestUrl = `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`;

  const response = await fetch(requestUrl, {
    method,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(content),
  });

  if (response.status === 401) {
    console.log("Unathorized access. Access token may be stale. Status: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error("Unauthorized access"));
  }

  if (response.status !== 200 && response.status !== 201) {
    console.log("An error was returned, code: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error(response.statusText));
  }
  return returnBody ? await response.json() : true;
};

export const deleteReport = async (accessToken: string, endpoint: string) => {
  const requestUrl = `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`;

  const response = await fetch(requestUrl, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status === 401) {
    console.log("Unathorized access. Access token may be stale. Status: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error("Unauthorized access"));
  }

  if (response.status !== 204) {
    console.log("An error was returned, code: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error(response.statusText));
  }

  return true;
};

export const contactUs = async (accessToken: string, content) => {
  const requestUrl = `${process.env.REACT_APP_API_ENDPOINT}/user-inquiries/contact-us`;

  const response = await fetch(requestUrl, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(content),
  });

  if (response.status === 401) {
    console.log("Unathorized access. Access token may be stale. Status: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error("Unauthorized access"));
  }

  if (response.status !== 204) {
    console.log("An error was returned, code: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error(response.statusText));
  }

  return true;
};
export const getReport = async (userToken: string, reportId: string, lang: string) => {
  return await getDataFromBE(userToken, `/reports/${reportId}?lang=${lang}`);
};

export const shareReports = async (userToken, emails, reportId, lang) => {
  const content = {
    emails,
    report_id: reportId,
    language: lang,
  };
  return await postReport(userToken, `/send-report`, content);
};
export const getDataFromBE = async (accessToken: string, endpoint: string) => {
  const requestUrl = `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`;

  const response = await fetch(requestUrl, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status === 401) {
    console.log("Unathorized access. Access token may be stale. Status: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error("Unauthorized access"));
  }

  if (response.status !== 200 && response.status !== 201) {
    console.log("An error was returned, code: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error(response.statusText));
  }

  return await response.json();
};

export const createReport = async (accessToken: string, content: object | string): Promise<any> => {
  const endpoint = `/reports`;

  const response = await postReport(accessToken, endpoint, content);
  return response;
};

export const createSection = async (accessToken: string, reportId: string, content: object | string): Promise<any> => {
  const endpoint = `/reports/${reportId}/sections`;

  const response = await postReport(accessToken, endpoint, content);
  return response;
};

export const exportItems = async (accessToken: string, endpoint: string, content: object | string) => {
  const requestUrl = `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`;

  const response = await fetch(requestUrl, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(content),
  });
  if (response.status === 401) {
    console.log("Unathorized access. Access token may be stale. Status: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error("Unauthorized access"));
  }
  if (response.status === 204) {
    return Promise.resolve(true);
  }
  if (response.status !== 201) {
    console.log("An error was returned, code: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error("action failed"));
  }

  return await response.json();
};

export const generateReport = async (accessToken: string, content: object | string) => {
  const endpoint = "/items/generate-report";
  const requestUrl = `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`;

  const response = await fetch(requestUrl, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(content),
  });
  if (response.status === 401) {
    console.log("Unathorized access. Access token may be stale. Status: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error("Unauthorized access"));
  }
  if (response.status !== 201 && response.status !== 400) {
    console.log("An error was returned, code: %o, text: %o", response.status, response.statusText);
    return Promise.reject(Error("Something went wrong"));
  }

  return await response.json();
};
