import styled from "styled-components";

export const Content = styled.div`
  position: relative;
`;

export const FloatingButton = styled.div<{ active: boolean }>`
  position: absolute;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  transition: all 0.2s;
  opacity: ${({ active }) => (active ? 1 : 0)};
  pointer-events: ${({ active }) => (active ? "all" : "none")};
`;
