export const RiskIntelligenceAdmin = "RiskIntelligenceAdmin";
export const RiskIntelligenceApprover = "RiskIntelligenceApprover";
export const RiskIntelligenceEditor = "RiskIntelligenceEditor";
export const AwarenessReportAdmin = "AwarenessReportAdmin";
export const AwarenessReportEditor = "AwarenessReportEditor";

export const AwarenessReportTester = "AwarenessReportTester";
export const AwarenessReportApprover = "AwarenessReportApprover";
export const AwarenessReportViewer = "AwarenessReportViewer";
export const AwarenessReportOnlyInAppViewer = "AwarenessReportOnlyInAppViewer";
export const AwarenessOnlyInAppViewer = "AwarenessOnlyInAppViewer";
export const InternalRiskIntelligenceViewer = "InternalRiskIntelligenceViewer";
export const InternalRiskIntelligenceAdmin = "InternalRiskIntelligenceAdmin";

export const CreateReportRoles = [
  RiskIntelligenceAdmin,
  AwarenessReportEditor,
  AwarenessReportApprover,
  AwarenessReportTester,
  RiskIntelligenceEditor,
  RiskIntelligenceApprover,
];

export const ApproverRoles = [RiskIntelligenceAdmin, AwarenessReportApprover, AwarenessReportTester, RiskIntelligenceApprover];
export const ShowEmailRoles = [AwarenessReportAdmin, AwarenessReportViewer, AwarenessReportTester, RiskIntelligenceAdmin, InternalRiskIntelligenceAdmin, InternalRiskIntelligenceViewer];
export const ShowContactUsRoles = [AwarenessReportAdmin, AwarenessReportViewer, AwarenessReportOnlyInAppViewer, RiskIntelligenceAdmin, AwarenessReportTester, InternalRiskIntelligenceAdmin, InternalRiskIntelligenceViewer];
export const ShareReportRoles = [AwarenessReportAdmin, AwarenessReportViewer, RiskIntelligenceAdmin, AwarenessReportTester, InternalRiskIntelligenceAdmin, InternalRiskIntelligenceViewer];
export const BriefsOnlyRoles = [InternalRiskIntelligenceAdmin, InternalRiskIntelligenceViewer];
export const ItemsHiddenRoles = [AwarenessReportViewer, AwarenessReportAdmin, AwarenessOnlyInAppViewer, InternalRiskIntelligenceAdmin, InternalRiskIntelligenceViewer];
export const ShareBriefRoles = [AwarenessReportAdmin, AwarenessReportViewer, RiskIntelligenceAdmin, AwarenessReportTester, InternalRiskIntelligenceAdmin, InternalRiskIntelligenceViewer];
