import { Badge, Breakpoints, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { format } from "date-fns";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLocal } from "../../../constants/generalSettings";
import { useUserData } from "../../../hooks/useUserData";
import { Report } from "../../../models/ReportModel";
import { useAppSelector } from "../../../store";
import { selectReports } from "../../../store/reports/ReportsSelectors";
import { selectLanguage } from "../../../store/user/UserSelectors";
import { getTranslatedTag } from "../../../utilities/tagsUtils";
import { getReport } from "../../../utilities/userUtils";
import { PreviewModalForReportList } from "../Preview/PreviewModalForReportList";
import AttachmentDownloadButton from "./ReportActions/AttachmentDownloadButton";
import { FlaggedItem } from "./ReportActions/FlaggedItem";
import { SeenItem } from "./ReportActions/SeenItem";
import { DateWrapper, TagsWrapper, TitleWrapper, Wrapper } from "./ReportElem.styles";
import { AttachmentWrapper } from "./styled";

interface ReportElemProps {
  report: Report;
  selectedReportId?: string;
  setSelectedReportId: (reportId: string) => void;
}

export const ReportElem: FC<ReportElemProps> = ({ report, selectedReportId, setSelectedReportId }) => {
  const { t, i18n } = useTranslation();
  const { userToken } = useUserData();
  const lang = useAppSelector(selectLanguage);
  const { categorizedDates: dates } = useAppSelector(selectReports);
  const date = new Date(report.schedule);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const isMobile = useHasMaxWidth(Breakpoints.L); // TODO: possible bug: L resolution is not mobile resolution

  const onClickHandler = () => {
    setSelectedReportId(report.report_id);
    if (isMobile) {
      setShowPreview(true);
    }
  };

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const report = await getReport(userToken, selectedReportId, lang);
        setSelectedReport(report);
      } catch (e) {
        console.log(e); // TODO: either error toast or error middleware
      }
    };
    if (showPreview && selectedReportId) {
      fetchReport();
    }
  }, [showPreview, selectedReportId, userToken, lang]);

  return (
    <>
      {dates[report.report_id] && (
        <Stack justifyContent="flex-end" mr="XS" data-testid="dateTag">
          <Text micro uppercase color="secondary">
            {t(dates[report.report_id])}
          </Text>
        </Stack>
      )}
      <Wrapper data-testid="ReportItem" onClick={onClickHandler} selected={selectedReportId === report.report_id}>
        <SeenItem report={report} selected={selectedReportId === report.report_id} />
        <TitleWrapper>
          <Text>{report.title}</Text>
        </TitleWrapper>
        <DateWrapper>
          <Text small>
            {date.toLocaleString(i18n.language, { weekday: "short" }) + " "} {format(date, "d MMM", { locale: getLocal(i18n.language) })}
          </Text>
          <Text small color="secondary">
            {format(date, "HH:mm", { locale: getLocal(i18n.language) })}
          </Text>
        </DateWrapper>
        <FlaggedItem report={report} isMobileFlow={isMobile} />
        <TagsWrapper>
          {[...new Set(report.tags)].map((elem) => (
            <Badge key={`${report.report_id}-${elem}`} label={getTranslatedTag(elem).toLocaleUpperCase()} />
          ))}
        </TagsWrapper>

        {report.attachment_ids.length > 0 && (
          <AttachmentWrapper>
            <AttachmentDownloadButton userToken={userToken} report={report} />
          </AttachmentWrapper>
        )}
      </Wrapper>
      <PreviewModalForReportList showPreview={showPreview} setShowPreview={setShowPreview} report={selectedReport} />
    </>
  );
};
