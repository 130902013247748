import { Button, Tooltip } from "@secuis/ccp-react-components";
import React, { useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import { NotificationTypes } from "../../../../context/notifications/NotificationMessage";
import { NotificationsContext } from "../../../../context/notifications/NotificationsContext";
import { useUserData } from "../../../../hooks/useUserData";
import { PatchContent } from "../../../../models/model";
import { createReport, updateReport } from "../../../../utilities/userUtils";

const SaveReport = ({
  scheduleDate,
  isChanged,
  savedReport,
  reportType,
  title,
  reportCreation,
  setIsApproved,
  setSavedReport,
  setUploadedData,
  uploadedScheduleDate,
  setTitle,
}) => {
  const { t } = useTranslation();
  const { userId, userToken } = useUserData();

  const { toast } = useContext(NotificationsContext);

  const [isCreationInProgress, setIsCreationInProgress] = useState(false);

  const showTooltip = !scheduleDate;

  const saveReport = async () => {
    try {
      if (!savedReport) {
        setIsCreationInProgress(true);
        const content = {
          report_type: reportType,
          title,
          report_date: reportCreation.toISOString(),
          schedule: scheduleDate.toISOString(),

          author: userId,
        };
        const response = await createReport(userToken, content);

        toast({
          title: t("CreateReport.toast.createReport", {
            type: reportType,
          }),
          type: NotificationTypes.Confirmation,
          icon: "Success",
        });
        setIsApproved(false);

        setUploadedData(scheduleDate, title);
        const id = response?.report_id;

        if (id) {
          setSavedReport(id);
          setTitle(title);
        }

        setIsCreationInProgress(false);
      } else {
        let content: PatchContent = {
          title,
        };

        if (uploadedScheduleDate !== scheduleDate) {
          content.schedule = scheduleDate.toISOString();
        }

        await updateReport(userToken, `/reports/${savedReport}`, content);

        setUploadedData(scheduleDate, title);
        toast({
          title: t("CreateReport.toast.updateReport", {
            type: reportType,
          }),
          type: NotificationTypes.Confirmation,
          icon: "Success",
        });
        setIsApproved(false);
      }
    } catch (e) {
      setIsCreationInProgress(false);
      toast({ title: e instanceof Error ? e.message : t("CreateReport.toast.failed"), type: NotificationTypes.Error, icon: "Error" });
    }
  };

  return (
    <>
      {!showTooltip ? (
        <Button
          data-testid="SaveReportButton"
          icon="Archive"
          mode="contained"
          color="accent"
          disabled={!isChanged || !scheduleDate || isCreationInProgress || !title}
          onClick={async () => saveReport()}
        >
          {t("NewReport.buttonSave")}
        </Button>
      ) : (
        <Tooltip message={t("CreateReport.schedule.tooltip.selectDate")} place="top" color="purple">
          <Button data-testid="SaveReportButton" icon="Archive" mode="contained" color="accent" disabled>
            {t("NewReport.buttonSave")}
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default SaveReport;
