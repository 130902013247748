import { useAppSelector } from "../../store"
import { selectUserData, selectUserId, selectUserToken } from "./UserSelectors"

export const useLoggedUser = () => {
  const userToken = useAppSelector(selectUserToken);
  const userId = useAppSelector(selectUserId);
  const userData = useAppSelector(selectUserData);

  return {
    userToken, userId, userData
  }
}