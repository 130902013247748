import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FetchStatus } from "../../models/ReportModel";
import { Sendout } from "../../models/SendoutModel";
import { SendoutFiltersModel } from "../../models/SendoutFiltersModel";
import { sendoutsApi } from "./SendoutsApi";

export type SendoutState = {
  selectedId?: string;
  editedId?: string;
  data: Sendout[];
  status: FetchStatus;
  page: number;
  showMore: boolean;
  filters?: SendoutFiltersModel;
  previewedSendoutId?: string;
};

export const initialState: SendoutState = {
  selectedId: null,
  editedId: null,
  data: [],
  status: "idle",
  page: 1,
  showMore: false,
  filters: {},
};

export const sendoutsSlice = createSlice({
  name: "sendouts",
  initialState,
  reducers: {
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
    setFilter: (state, action: PayloadAction<SendoutFiltersModel>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setSelectedId: (state, action: PayloadAction<string>) => {
      state.selectedId = action.payload;
    },
    setEditedId: (state, action: PayloadAction<string>) => {
      state.editedId = action.payload;
    },
    setPreviewedSendout: (state, action: PayloadAction<string>) => {
      state.previewedSendoutId = action.payload;
    },
    clearPreviewedSendout: (state) => {
      state.previewedSendoutId = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(sendoutsApi.endpoints.getSendouts.matchPending, (state, action) => {
        state.status = action.meta.arg.originalArgs.page === 1 ? action.meta.requestStatus : state.status;
      })
      .addMatcher(sendoutsApi.endpoints.getSendouts.matchRejected, (state, action) => {
        state.status = action.meta.requestStatus;
        state.data = action.meta.arg.originalArgs.page === 1 ? initialState.data : state.data;
      })
      .addMatcher(sendoutsApi.endpoints.getSendouts.matchFulfilled, (state, action) => {
        if (action.payload?.data) {
          state.data = action.meta.arg.originalArgs.page === 1 ? action.payload.data : [...state.data, ...action.payload.data];
          state.page = action.payload.meta.page ?? state.page;
          state.showMore = action.payload.meta.count ? action.payload.meta.count === action.payload.meta.limit : false;
          state.status = action.meta.requestStatus;
        }
      })
      .addMatcher(sendoutsApi.endpoints.updateSendout.matchFulfilled, (state, action) => {
        if (action?.payload) {
          state.data = state.data.map((sendout) => sendout.id === action.payload.id ? action.payload : sendout);
        }
      })
  },
});

export const sendoutsActions = sendoutsSlice.actions;
