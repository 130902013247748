import { Breakpoints, Link, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components/macro";

export const ToolbarWrapper = styled(Stack)`
  background-color: ${Palette.Navy800};
  z-index: 1;
  display: flex;
  flex-direction: row;

  [role="listbox"] {
    overflow-y: scroll;
  }
`;

interface ToolbarContentProps {
  $isExpanded?: boolean;
  $isMobile?: boolean; // TODO: Remove this prop, use @media query to detect mobile resolution
}

export const ToolbarContent = styled.div<ToolbarContentProps>`
  padding-top: ${Spacing.M}px;
  display: flex;
  flex-direction: column;
  height: 100%;
  
  @media (max-width: ${Breakpoints.XS}) {
    ${({ $isExpanded }) =>
    !$isExpanded &&
    css`
        margin-top: 0;
        padding: 0;
        margin: 0;
      `};
  }

  ${({ $isExpanded }) =>
    $isExpanded &&
    css`
      opacity: 1;
      width: 320px;

      @media (max-width: ${Breakpoints.XS}) {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        background: ${Palette.Navy800};
        padding-top: 0;
      }
    `};

  ${({ $isExpanded, $isMobile }) =>
    !$isExpanded &&
    !$isMobile &&
    css`
      opacity: 0;
      width: 72px;
    `};

  ${({ $isExpanded, $isMobile }) =>
    !$isExpanded &&
    $isMobile &&
    css`
      display: none;
    `};
`;

export const ToolbarContentWrapper = styled(Stack).attrs({
  direction: "column",
  justifyContent: "flex-start",
  ml: "S",
  pr: "S",
  flex: "100%"
}) <{ $isFilterSelectorExpanded?: boolean; $isCollection?: boolean }>`
  overflow-y: auto;
  scrollbar-width: auto;
  display: flex;
  flex-direction: column;
  z-index: ${({ $isCollection }) => $isCollection ? 100 : 1001}px;

  @media (max-width: ${Breakpoints.XS}) {
    padding-bottom: 140px;
    z-index: ${({ $isFilterSelectorExpanded }) => $isFilterSelectorExpanded ? `1001` : `100`};
  }
`;

export const MainLine = styled(Stack).attrs({
  justifyContent: "space-between",
  alignItems: "flex-start",
  direction: "column",
  gap: "S"
})`
  padding-bottom: ${Spacing.S}px;

  @media (max-width: ${Breakpoints.XS}) {
    padding-top: ${Spacing.S}px;
  }

  > :nth-child(3) {
    display: none;

    @media (max-width: ${Breakpoints.XS}) {
      display: block;
    }
  }
`;

export const StickyWrapper = styled.div`
  position: sticky;
  top: 0;
  background-color: ${Palette.Navy800};
  z-index: 2;
  margin: 0 ${Spacing.S}px;
`;

export const ToolbarFooter = styled(Stack).attrs({
  direction: "column",
  flex: 1,
  gap: "S",
})`
  justify-content: flex-end;
  z-index: 1000;

  @media (max-width: ${Breakpoints.XS}) {
    position: fixed;
    bottom: 0;
    z-index: 1000;
    width: 100vw;
    margin: 0;
    left: 0;
  }
`;

export const ToolbarFooterWrapper = styled(Stack).attrs({
  direction: "column",
  alignItems: "center",
  gap: "S",
  p: "S"
})`
  padding-bottom: ${Spacing.L}px;
  padding-top: ${Spacing.M}px;
  background-color: ${Palette.Navy750};
  box-shadow: 0px -8px 6px -3px rgba(0, 0, 0, 0.16);
`;
