import { Breakpoints, Icon, Stack, Text, Title, useHasMaxWidth } from "@secuis/ccp-react-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useReportDataSections } from "../../../../hooks/useReportDataSections";
import { useSearchParam } from "../../../../hooks/useSearchParam";
import { useSection } from "../../../../hooks/useSection";
import { useTags } from "../../../../hooks/useTags";
import { Report } from "../../../../models/ReportModel";
import { SECTION_STATUS } from "../../../../models/SectionModel";
import { useLoggedUser } from "../../../../store/user/LoggedUserHooks";
import { useUser } from "../../../../store/user/UserHooks";
import { transformNames } from "../../../../utilities/reportUtils";
import { getDataFromBE } from "../../../../utilities/userUtils";
import { LoadingWrapper } from "../../../Settings/styled";
import { ContentSections } from "../../../styled";
import { EditorWrapper } from "../EditorWrapper";
import PreviewButton from "../Preview/PreviewButton";
import { TagsComponent } from "../Tags/TagsComponent";
import { ActionsHeader } from "../styled";
import ApproveSection from "./ApproveSection";
import CancelSections from "./CancelSections";
import { EditorContainer } from "./CreateSection.styles";
import { DataDetailsComponentSections } from "./DataDetailsComponentSections";
import SaveSection from "./SaveSection";
import SectionToggle from "./SectionToggle";
import { SectionActionsWrapper, SectionDetailsWrapper, SectionNameWrapper, SectionsEditorWrapper, SectionsWrapper } from "./styled";

export const CreateSection = () => {
  const sectionIdElem = useSearchParam("section");
  const { t, i18n } = useTranslation();
  const { userData, userToken } = useLoggedUser();
  const { section } = useSection();
  const navigate = useNavigate();
  const {
    reportCreation,
    scheduleDate,
    setScheduleDate,
    tags,
    savedTags,
    reportType,
    htmlContent,
    setHtmlContent,
    uploadedHtmlContent,
    title,
    isChanged,
    savedReport,
    setInitSavedReportData,
    id,
    type,
    setUploadedDataSectionData,
    setInitSectionData,
    attachmentId,
  } = useReportDataSections({ i18n });
  const isMobile = useHasMaxWidth(Breakpoints.XS);
  const [initIndex, setInitIndex] = useState(0);
  const [reportData, setReportData] = useState<Report>(null);
  const { userName: sectionApproverName } = useUser(section?.approval_user);
  const { userName: sectionAuthorName } = useUser(section?.author);
  const { userName: reportAuthorName } = useUser(reportData?.author);
  const [approver, setApprover] = useState(null);
  const [author, setAuthor] = useState(null);
  const [isApproved, setIsApproved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [elemSectionId, setElemSectionId] = useState("");
  const { allTagsCategories } = useTags();

  const navigateToCreateReport = () =>
    navigate({
      pathname: "/createReport",
      search: new URLSearchParams({ type: type, id: id }).toString(),
    });

  const checkNoTags = () => {
    if (initIndex === -1) {
      if (reportType === "Global Weekly" || reportType === "Global Daily") {
        return false;
      }
      return !allTagsCategories;
    } else {
      return !tags[initIndex].categories.length;
    }
  };

  useEffect(() => {
    if (sectionIdElem) {
      setElemSectionId(sectionIdElem);
    }
  }, [sectionIdElem]);

  useEffect(() => {
    if (tags && section?.name) {
      setInitIndex(tags.findIndex((elem) => elem.region === section.name));
    }
  }, []);

  useEffect(() => {
    if (!isApproved || !isReady) {
      setIsApproved(false);
    }
  }, [isApproved, isReady]);

  useEffect(() => {
    const loadReport = async () => {
      if (id) {
        const data = await getDataFromBE(userToken, `/reports/${id}`);
        setReportData(data);
        setInitSavedReportData(data);
        setIsReady(section.is_done);
        setIsApproved(section.status === SECTION_STATUS.APPROVED);
      }
    };
    if (userToken) {
      loadReport();
    }
  }, [id, userToken]);

  useEffect(() => {
    if (section) {
      setInitSectionData(section.tags, section.content);
    }
  }, []);

  return (
    <SectionsWrapper>
      <ContentSections>
        {!isMobile && (
          <>
            <Title>
              {id ? (
                <>
                  {t("Reports.edit")} {title}
                </>
              ) : (
                <>
                  {t("NewReport.title")} {t(`reports.${transformNames(type)}.title`)}
                </>
              )}
            </Title>

            {
              <ActionsHeader>
                <Stack gap="L">
                  <CancelSections isContentChanged={isChanged} title={"Section.report"} navigateTo={navigateToCreateReport} />
                  <div>
                    <PreviewButton htmlContent={[htmlContent]} title="Section.preview" />
                  </div>
                  <SaveSection
                    isChanged={isChanged}
                    savedReport={savedReport}
                    htmlContent={htmlContent}
                    reportType={reportType}
                    setIsApproved={setIsApproved}
                    tags={tags}
                    setUploadedDataSectionData={setUploadedDataSectionData}
                    uploadedHtmlContent={uploadedHtmlContent}
                    savedTags={savedTags}
                    name={section?.name}
                    sectionIdElem={elemSectionId}
                    setElemSectionId={setElemSectionId}
                    setIsReady={setIsReady}
                    setCreator={setAuthor}
                  />
                </Stack>
              </ActionsHeader>
            }
            <div>
              <DataDetailsComponentSections
                isDisabled
                reportCreation={reportCreation}
                setScheduleDate={setScheduleDate}
                scheduleDate={scheduleDate}
                reportType={reportType}
                savedReport={savedReport}
                attachmentId={attachmentId}
                setIsApproved={setIsApproved}
                author={reportAuthorName}
              />
            </div>
            <SectionsEditorWrapper>
              <EditorContainer>
                {!loading && <EditorWrapper setLoading={setLoading} setHtmlContent={setHtmlContent} value={htmlContent} />}
                <SectionActionsWrapper>
                  <SectionNameWrapper>
                    <Icon variant="Location"></Icon>
                    <Text>{t(`tag.${section.name}.region`)}</Text>
                  </SectionNameWrapper>
                  <SectionDetailsWrapper>
                    <div>
                      <Text uppercase micro color="secondary">
                        {t("Reports.author")}
                      </Text>
                      <Text>{elemSectionId ? author || sectionAuthorName : userData.name}</Text>
                    </div>
                    <div />
                    <div>
                      <Text uppercase micro color="secondary">
                        {t("Reports.approver")}
                      </Text>
                      <Text>{approver || sectionApproverName}</Text>
                    </div>
                  </SectionDetailsWrapper>
                  <TagsComponent reportType={reportType} initIndex={initIndex} region={section.name} />
                  {savedReport && elemSectionId && (
                    <>
                      <SectionToggle
                        noTags={checkNoTags()}
                        isChanged={isChanged}
                        setIsReady={setIsReady}
                        isReady={isReady}
                        elemSectionId={elemSectionId}
                        savedReport={savedReport}
                      />
                      <ApproveSection
                        isChanged={isChanged}
                        isApproved={isApproved}
                        isReady={isReady}
                        elemSectionId={elemSectionId}
                        savedReport={savedReport}
                        setApprover={setApprover}
                        setIsApproved={setIsApproved}
                      />
                    </>
                  )}
                </SectionActionsWrapper>
              </EditorContainer>
            </SectionsEditorWrapper>
          </>
        )}
        {isMobile && (
          <LoadingWrapper>
            <Text>{t("Reports.mobileNotSupportedMessage")}</Text>
          </LoadingWrapper>
        )}
      </ContentSections>
    </SectionsWrapper>
  );
};
