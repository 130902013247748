import { ButtonCompact, ButtonText, Checkbox, ChipFilter, EmailRegex, Input, Modal, Stack } from "@secuis/ccp-react-components";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationTypes } from "../../context/notifications/NotificationMessage";
import { NotificationsContext } from "../../context/notifications/NotificationsContext";
import { useRoles } from "../../hooks/useRoles";
import { useAppSelector } from "../../store";
import { useShareBriefMutation } from "../../store/briefs/BriefsApi";
import { useLoggedUser } from "../../store/user/LoggedUserHooks";
import { selectLanguage } from "../../store/user/UserSelectors";
import { shareReports } from "../../utilities/userUtils";
import { ChipsStack } from "./ShareComponent.styles";

interface ShareComponentProps {
  type: "report" | "brief";
  id?: string;
}

export const ShareComponent = ({ type, id }: ShareComponentProps) => {
  const { userData, userToken } = useLoggedUser();
  const lang = useAppSelector(selectLanguage);
  const { toast } = useContext(NotificationsContext);
  const { t } = useTranslation();

  const { canShareReport, canShareBrief } = useRoles();
  const canShare = type === "report" ? canShareReport : canShareBrief;

  const [shareBrief] = useShareBriefMutation();

  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [receivers, setReceivers] = useState<string[]>([]);
  const [sendEmailToMe, setSendEmailToMe] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const closeModal = () => {
    setError(null);
    setEmail("");
    setReceivers([]);
    setModalOpened(false);
    setSendEmailToMe(false);
  };

  const addEmail = () => {
    const reg = new RegExp(EmailRegex);
    if (reg.exec(email)) {
      setReceivers([...receivers, email]);
      setEmail("");
      setError(null);
    } else {
      setError(t("share.email.incorrect"));
    }
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      addEmail();
    }
  };

  const removeEmail = (email) => {
    const newList = receivers.filter((e) => e !== email);
    setReceivers(newList);
  };

  const shareEmails = () => {
    const emailList = sendEmailToMe ? [...receivers, userData.email] : [...receivers];

    if (emailList.length === 0) {
      toast({ title: t("share.email.emptyList"), type: NotificationTypes.Warning, icon: "Info" });
      return;
    }

    const handleSuccess = () => {
      toast({ title: t(`share.${type}ShareSuccess`), type: NotificationTypes.Confirmation, icon: "Success" });
      closeModal();
    };

    const handleError = () => {
      toast({ title: t(`share.${type}ShareFailed`), type: NotificationTypes.Error, icon: "Error" });
    };

    switch (type) {
      case "report":
        shareReports(userToken, emailList, id, lang).then(handleSuccess).catch(handleError);
        break;

      case "brief":
        shareBrief({ briefId: id, emails: emailList }).unwrap().then(handleSuccess).catch(handleError);
        break;

      default:
        break;
    }
  };

  return canShare ? (
    <>
      <ButtonCompact icon="Share" mode="outlined" title={t("common.share")} onClick={() => setModalOpened(true)}>
        {t("common.share")}
      </ButtonCompact>
      <Modal
        title={t(`share.${type}`)}
        size="L"
        isOpen={modalOpened}
        onClose={closeModal}
        actions={[
          {
            children: t(`share.${type}`),
            onClick: shareEmails,
          },
        ]}
      >
        <Stack direction="column" gap="XS">
          <Input
            icon="Add"
            title={t("share.pressEnterOrClickIcon")}
            onIconClick={addEmail}
            error={error}
            placeholder={t("share.email.placeholder")}
            value={email}
            onBlur={() => setError(null)}
            onChange={(e) => setEmail(e.target.value)}
            onKeyUp={handleEnter}
          />
          <ChipsStack $chipsNumber={receivers.length} gap="XS" flexWrap="wrap" data-testid="stack-chips">
            {receivers.map((email) => (
              <ChipFilter key={email} label={email} onRemove={() => removeEmail(email)} />
            ))}
            {receivers.length > 0 && (
              <Stack alignItems="center">
                <ButtonText onClick={() => setReceivers([])}>{t("share.buttonClearAll")}</ButtonText>
              </Stack>
            )}
          </ChipsStack>
          <Checkbox id="sendEmailToMe" label={t("share.emailToMe")} checked={sendEmailToMe} onChange={() => setSendEmailToMe(!sendEmailToMe)} />
        </Stack>
      </Modal>
    </>
  ) : (
    <></>
  );
};
