import { Palette } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: ${Palette.Navy700};
  padding-bottom: 10px;
  display: flex;
  gap: 10px;
`;

export const Container = styled.div`
  scroll-margin-top: 40px;
`;
