import React, { FC, useEffect, useState } from "react";
import { useSearchParam } from "../../../hooks/useSearchParam";
import { useUserData } from "../../../hooks/useUserData";
import { useAppSelector } from "../../../store";
import { selectLanguage } from "../../../store/user/UserSelectors";
import { getReport } from "../../../utilities/userUtils";
import { PreviewModalForReportList } from "./PreviewModalForReportList";

interface ReportPreviewFromEmailProps {
  setSelectedReportId: (reportId: string) => void;
}

export const ReportPreviewFromEmail: FC<ReportPreviewFromEmailProps> = ({ setSelectedReportId }) => {
  const c = useSearchParam("c");
  const { userToken } = useUserData();
  const lang = useAppSelector(selectLanguage);
  const [showPreview, setShowPreview] = useState(false);
  const [report, setReport] = useState(null);

  useEffect(() => {
    const fetchReport = async (c) => {
      try {
        const report = await getReport(userToken, c, lang);
        setReport(report);
        setShowPreview(true);
        setSelectedReportId(report.report_id);
      } catch (e) {
        console.log(e); // TODO: either error toast or error middleware
      }
    };
    if (c && userToken && lang) {
      fetchReport(c);
    }
  }, [c, userToken, lang]);

  return <PreviewModalForReportList showPreview={showPreview} setShowPreview={setShowPreview} report={report} />;
};
