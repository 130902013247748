import { Dropdown, Modal, Subtitle, Text, Textarea } from "@secuis/ccp-react-components";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationTypes } from "../../context/notifications/NotificationMessage";
import { NotificationsContext } from "../../context/notifications/NotificationsContext";
import { useUserData } from "../../hooks/useUserData";
import { useLoggedUser } from "../../store/user/LoggedUserHooks";
import { contactUs } from "../../utilities/userUtils";
import { ContactUsDropdown, FinalStep, ModalWrapped, TextareaWrapper, TitleWrapper } from "./styled";

export const ContactUs = ({ showContactUs, setShowContactUs }) => {
  const { t } = useTranslation();
  const { userToken } = useUserData();
  const { userData } = useLoggedUser();
  const { toast } = useContext(NotificationsContext);
  const MODAL_CONTENT = [
    { title: t("ContactUs.title", { name: userData?.name }), button: t("ContactUs.sendButton") },
    { title: "", button: t("ContactUs.confirm.close") },
  ];
  const [index, setIndex] = useState(0);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [content, setContent] = useState("");
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);

  const resetContent = () => {
    setIndex(0);
    setShowContactUs(false);
    setMessage("");
    setType("");
    setContent("");
  };

  const onCloseHandler = () => {
    setShowContactUs(false);
    setIndex(0);
    resetContent();
  };

  const onClickHandler = () => {
    if (index === 0) {
      sendMessage();
    } else {
      resetContent();
    }
  };

  const sendMessage = async () => {
    const request = { author_email: userData.email, type_of_message: type, content_type: content, message };
    try {
      await contactUs(userToken, request);
      setIndex(1);
    } catch (e) {
      toast({ title: t("settings.notification.failed"), type: NotificationTypes.Error, icon: "Error" });
    }
  };

  useEffect(() => {
    setIsSendButtonDisabled(index === 0 && (!message || !type || (!content && type === "Content")));
  }, [index, message, type, content]);

  useEffect(() => {
    if (type !== "Content") {
      setContent("");
    }
  }, [type]);

  return (
    <ModalWrapped>
      <Modal
        isOpen={showContactUs}
        size="L"
        title={MODAL_CONTENT[index].title}
        onClose={onCloseHandler}
        actions={[
          {
            children: MODAL_CONTENT[index].button,
            disabled: isSendButtonDisabled,
            onClick: onClickHandler,
          },
        ]}
      >
        {index === 0 ? (
          <>
            <Text>{t("ContactUs.description")}</Text>
            <Text bold>{userData?.email}</Text>
            <ContactUsDropdown>
              <Dropdown
                placeholder={t("ContactUs.topicPlaceholder")}
                onChange={setType}
                options={[
                  { title: t("ContactUs.Technical"), value: "Technical" },
                  { title: t("ContactUs.Content"), value: "Content" },
                  { title: t("ContactUs.Other"), value: "Other" },
                ]}
                sheetCancelLabel="Cancel"
                value={type}
              />
            </ContactUsDropdown>
            {type === "Content" && (
              <ContactUsDropdown>
                <Dropdown
                  placeholder={t("ContactUs.typePlaceholder")}
                  onChange={setContent}
                  options={[
                    { title: t("ContactUs.Old_Intelligence"), value: "Old_Intelligence" },
                    { title: t("ContactUs.New_Intelligence"), value: "New_Intelligence" },
                    { title: t("ContactUs.Other"), value: "Other" },
                  ]}
                  sheetCancelLabel="Cancel"
                  value={content}
                />
              </ContactUsDropdown>
            )}
            <TextareaWrapper>
              <Textarea
                label={t("ContactUs.messagePlaceholder")}
                placeholder={t("ContactUs.messagePlaceholder")}
                onChange={(ev) => setMessage(ev.target.value)}
                value={message}
              ></Textarea>
            </TextareaWrapper>
          </>
        ) : (
          <FinalStep>
            <TitleWrapper>
              <Subtitle centered>{t("ContactUs.confirm.title")}</Subtitle>
            </TitleWrapper>
            <Text centered>{t("ContactUs.confirm.description")}</Text>
          </FinalStep>
        )}
      </Modal>
    </ModalWrapped>
  );
};
