import { Badge, Box, Headline, LoadingIndicator, Stack, Text } from "@secuis/ccp-react-components";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ITEM_STATUS, Item } from "../../../models/ItemModel";
import { useUser } from "../../../store/user/UserHooks";
import { getDateOfEvent } from "../../../utilities/Item.helpers";
import { getLocalDate } from "../../../utilities/dateUtils";
import { getColor } from "../../../utilities/sectionUtils";
import { getTranslatedTag } from "../../../utilities/tagsUtils";
import { ItemButtons } from "./ItemButtons";
import { Parameter } from "./Parameter";
import { ItemContainer, ItemOthers, ItemParameters, ItemTags, ParametersWrapper } from "./styled";

export const TRENDS = {
  uppcomming: {
    icon: "TrendUp",
    color: "success",
  },
  cancel: {
    icon: "TrendDown",
    color: "error",
  },
  ongoing: {
    icon: "TrendMiddle",
    color: "neutral",
  },
};

interface ItemLongProps {
  itemData: Item;
  last: boolean;
}

export const ItemLong: FC<ItemLongProps> = ({ itemData, last }) => {
  const { userName: authorName, isLoading } = useUser(itemData.added_by);
  const { t, i18n } = useTranslation();
  const location = `${itemData?.settlement ?? "-"}, ${itemData?.country ?? "-"} `;
  const isDisabled = itemData.is_internal || itemData.is_gold_brief || itemData.event_status === "canceled" || itemData.item_status !== ITEM_STATUS.APPROVED;

  return (
    <ItemContainer data-testid="long-item-element" selected={false}>
      <Stack flex="1" mb="S">
        <Parameter label={t("Item.title")} icon="Image" disabled={!itemData.image_url} defWidth={false} truncate={false} item noWrapper>
          <Headline>{itemData.title}</Headline>
        </Parameter>
      </Stack>
      <ItemParameters>
        <Parameter label={t("Item.dateOfEvent")} icon="Calendar" large item>
          {getDateOfEvent(itemData)}
        </Parameter>
        <Parameter label={t("Items.location")} icon="Location" item>
          {location}
        </Parameter>
        <Parameter label={t("Item.lastUpdated")} icon="Time" item>
          {getLocalDate(itemData.date_last_updated, i18n.language, true)}
        </Parameter>
        <Parameter label={t("NewReport.labelAuthor")} icon="Edit" noWrapper item>
          {isLoading ? (
            <LoadingIndicator size="S" />
          ) : (
            <Text color="white" title={authorName} truncate>
              {authorName}
            </Text>
          )}
        </Parameter>
        <Parameter label={t("Item.repeat")} icon="Status" small item>
          {itemData.recurrence ? "Yes" : "No"}
        </Parameter>
        <Parameter label={t("Item.threatLevel")} icon="Show" medium item>
          {!itemData.threat_level ? " - " : t(`Items.threatLevels.${itemData.threat_level}`)}
        </Parameter>
        <Parameter label={t("Reports.draft.status")} icon={TRENDS[itemData.event_status]?.icon} iconColor={TRENDS[itemData.event_status]?.color} medium item>
          {t(`Items.eventStatus.${itemData.event_status}`)}
        </Parameter>
      </ItemParameters>
      <ItemOthers>
        <ParametersWrapper>
          <ItemTags>
            {[...new Set(itemData.threat_risk_categories)].map((tag) => (
              <Badge key={`${itemData.id}-${tag}`} label={getTranslatedTag(tag)} />
            ))}
            {itemData.item_status && (
              <Box marginLeft={"S"}>
                <Badge label={t(`Section.status.${itemData.item_status}`)} mode="outlined" color={getColor(itemData.item_status)} />
              </Box>
            )}
            {itemData?.is_internal && <Badge label={t("Items.internal")} mode="outlined" color="neutral" />}
            {itemData?.is_gold_brief && <Badge label={t("Items.organisation")} mode="outlined" color="neutral" />}
          </ItemTags>
        </ParametersWrapper>
        <ItemButtons id={itemData.id} last={last} isDisabled={isDisabled} />
      </ItemOthers>
    </ItemContainer>
  );
};
