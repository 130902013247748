import { Breakpoints, Link } from "@secuis/ccp-react-components";
import styled from "styled-components/macro";

export const DropdownWrapper = styled.div`
  div[role="listbox"] {
    scrollbar-width: auto;
  }
`;

export const DropdownWrapperFixed = styled.div`
  @media (max-width: ${Breakpoints.XS}) {
    div[role="group"] {
      > div:nth-child(2) {
        position: fixed;
      }
    }
  }
  div[role="listbox"] {
    scrollbar-width: auto;
    div {
      word-break: keep-all;
    }
  }
`;

export const UnselectAllLink = styled(Link)`
  text-decoration: none;
`;
