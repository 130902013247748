import { ButtonCompact } from "@secuis/ccp-react-components";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SENDOUT_STATUS, SendoutType } from "../../../models/SendoutModel";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectItemsCollection } from "../../../store/items/ItemsSelectors";
import { itemsActions } from "../../../store/items/ItemsSlice";
import { useGetSendoutQuery } from "../../../store/sendouts/SendoutsApi";
import { selectSendoutsEditedId, selectSendoutsSelectedId } from "../../../store/sendouts/SendoutsSelectors";
import { sendoutsActions } from "../../../store/sendouts/SendoutsSlice";
import { selectToolbar } from "../../../store/toolbar/ToolbarSelectors";
import { toolbarActions } from "../../../store/toolbar/ToolbarSlice";
import { CollectedBriefs } from "./Collections/CollectedBriefs";
import { HeadModal } from "./Collections/HeadModal";
import { FiltersContent } from "./Filters/FiltersContent";
import { ItemsFiltersContent } from "./Filters/ItemsFiltersContent";
import { SendoutsFiltersContent } from "./Filters/SendoutsFiltersContent";

type UseToolbar = {
  isFilterSelectorExpanded: boolean;
  setIsFilterSelectorExpanded: (val: boolean) => void;
  handleExpand: (val: boolean) => void;
  toolbar: {
    check: boolean;
    content: JSX.Element;
    title: string;
    head?: JSX.Element;
    modal?: JSX.Element;
  };
};

export const useToolbar = (): UseToolbar => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { contentType, isOpen, storeType } = useAppSelector(selectToolbar);
  const [isFilterSelectorExpanded, setIsFilterSelectorExpanded] = useState<boolean>(false);
  const [isEmptyCartModalOpen, setIsEmptyCartModalOpen] = useState<boolean>(false);
  const sendoutSelectedId = useAppSelector(selectSendoutsSelectedId);
  const sendoutEditedId = useAppSelector(selectSendoutsEditedId);
  const collectedItems = useAppSelector(selectItemsCollection);
  const { data: fetchedData } = useGetSendoutQuery({ sendoutId: sendoutSelectedId }, { skip: !sendoutSelectedId, refetchOnMountOrArgChange: true });
  const { refetch } = useGetSendoutQuery({ sendoutId: sendoutEditedId }, { skip: !sendoutEditedId, refetchOnMountOrArgChange: false });

  const handleExpand = (value: boolean) => {
    dispatch(toolbarActions.setToolbar({ isOpen: value }));
    !value && contentType === "collection" && dispatch(sendoutsActions.setSelectedId(null));
  };

  const editSendoutContent = async () => {
    dispatch(sendoutsActions.setSelectedId(null));
    dispatch(sendoutsActions.setEditedId(sendoutSelectedId));
    navigate("/items");
  };

  const onRefresh = () => {
    refetch()
      .unwrap()
      .then((sendouts) => {
        dispatch(itemsActions.setCollectedItemsFromSendout(sendouts.items));
        dispatch(itemsActions.setCollectionType(sendouts.type as SendoutType));
        dispatch(itemsActions.setCollectionDate(sendouts.schedule));
      })
      .catch(() => true);
  };

  const collectionHead = useMemo(() => {
    if (!!sendoutSelectedId) {
      return (
        <ButtonCompact
          mode="outlined"
          icon="Edit"
          onClick={editSendoutContent}
          disabled={fetchedData?.status === SENDOUT_STATUS.canceled || fetchedData?.status === SENDOUT_STATUS.published}
        >
          {t("Sendout.editContent")}
        </ButtonCompact>
      );
    } else if (!!sendoutEditedId) {
      return (
        <ButtonCompact mode="outlined" onClick={onRefresh} icon="Reload">
          {t("Sendout.toolbarList.refresh")}
        </ButtonCompact>
      );
    } else {
      return (
        <ButtonCompact mode="outlined" onClick={() => setIsEmptyCartModalOpen(true)} disabled={collectedItems?.length === 0} icon="Delete">
          {t("Items.collection.emptyCart")}
        </ButtonCompact>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendoutSelectedId, sendoutEditedId, editSendoutContent, fetchedData?.status, collectedItems?.length]);

  const collectionTitle = useMemo(() => {
    if (!!sendoutSelectedId) {
      return t("Toolbar.collections.title.select");
    } else if (!!sendoutEditedId) {
      return t("Toolbar.collections.title.edit");
    } else {
      return t("Toolbar.collections.title.new");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendoutSelectedId, sendoutEditedId]);

  const toolbar = useMemo(
    () =>
      [
        {
          check: contentType === "filters",
          content: [
            storeType === "reports" && <FiltersContent />,
            (storeType === "items" || storeType === "briefs") && <ItemsFiltersContent />,
            storeType === "sendouts" && <SendoutsFiltersContent />,
          ].find((content) => content),
          title: t("Filter.title"),
        },
        {
          check: contentType === "collection",
          content: <CollectedBriefs />,
          title: collectionTitle,
          head: collectionHead,
          modal: <HeadModal setIsEmptyCartModalOpen={setIsEmptyCartModalOpen} isEmptyCartModalOpen={isEmptyCartModalOpen} />,
        },
      ].find(({ check }) => check),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentType, isOpen, storeType, collectionHead, isEmptyCartModalOpen]
  );

  return {
    isFilterSelectorExpanded,
    setIsFilterSelectorExpanded,
    handleExpand,
    toolbar,
  };
};
