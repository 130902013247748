import { Modal, Stack, Text } from "@secuis/ccp-react-components";
import { addDays } from "date-fns";
import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { NotificationTypes } from "../../../context/notifications/NotificationMessage";
import { NotificationsContext } from "../../../context/notifications/NotificationsContext";
import { ItemFiltersModel } from "../../../models/ItemFiltersModel";
import { useExportItemsMutation } from "../../../store/items/ItemsApi";
import { showEventDate } from "../../../utilities/dateUtils";
import { downloadFile } from "../../../utilities/fileUtils";
import { ExportModalFilter } from "./ExportModalFilter";

interface ExportItemsModalProps {
  isOpen: boolean;
  onClose: () => void;
  filters: ItemFiltersModel;
}

export const ExportItemsModal: FC<ExportItemsModalProps> = ({ isOpen, onClose, filters }) => {
  const { t } = useTranslation();
  const { toast } = useContext(NotificationsContext);
  const [exportItems] = useExportItemsMutation();

  const exportToCSV = () => {
    onClose();
    exportItems(filters)
      .unwrap()
      .then((file) => {
        downloadFile(file, "csv", "text/csv");
        toast({ title: t("Items.exportToCsv.toast.success"), type: NotificationTypes.Confirmation, icon: "Success" });
      })
      .catch(() => true);
  };

  return (
    <Modal
      isOpen={isOpen}
      size="S"
      title={t("Items.exportToCsv.modal.title")}
      onClose={onClose}
      actions={[
        {
          children: t("Items.exportToCsv.modal.action.export"),
          onClick: exportToCSV,
        },
        {
          children: t("NewReport.buttonCancel"),
          onClick: onClose,
        },
      ]}
    >
      <Text>{t("Items.exportToCsv.modal.question")}</Text>
      <Stack direction="column" gap="S" pt="M">
        {filters.free_search && <ExportModalFilter label={t("Filter.freeSearch")} value={filters.free_search} />}
        {filters.item_statuses?.length > 0 && (
          <ExportModalFilter label={t("Filter.status")} value={filters.item_statuses.map((status) => t(`Filter.statuses.${status}`)).join(", ")} />
        )}
        {filters.threat_levels?.length > 0 && (
          <ExportModalFilter
            label={t("Filter.threat_level")}
            value={filters.threat_levels.map((threatLevel) => t(`Items.threatLevels.${threatLevel}`)).join(", ")}
          />
        )}
        {filters.region && <ExportModalFilter label={t("Items.exportToCsv.modal.filters.region")} value={t(`tag.${filters.region}.region`)} />}
        {filters.country && <ExportModalFilter label={t("Items.exportToCsv.modal.filters.country")} value={filters.country} />}
        {filters.settlement && <ExportModalFilter label={t("Filter.city")} value={filters.settlement} />}
        {filters.threat_risk_categories?.length > 0 && (
          <ExportModalFilter
            label={t("Items.exportToCsv.modal.filters.categories")}
            value={filters.threat_risk_categories.map((tag) => t(`tag.${tag}.name`)).join(", ")}
          />
        )}
        {filters.sector_specific?.length > 0 && <ExportModalFilter label={t("Filter.sector")} value={filters.sector_specific.join(", ")} />}
        {filters.start_date && filters.end_date ? (
          <ExportModalFilter label={t("Items.exportToCsv.modal.filters.dates")} value={showEventDate(filters.start_date, filters.end_date, "dd/MM/yyyy")} />
        ) : (
          <ExportModalFilter
            label={t("Items.exportToCsv.modal.filters.dates")}
            value={showEventDate(new Date().toString(), addDays(new Date(), 28).toString(), "dd/MM/yyyy")}
          />
        )}
      </Stack>
    </Modal>
  );
};
