import { Breakpoints, Headline, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import React from "react";
import { useAppSelector } from "../../../store";
import { selectToolbar } from "../../../store/toolbar/ToolbarSelectors";
import { FilterMobileButton } from "./Filters/FilterMobileButton";
import { FiltersButton } from "./Filters/FiltersButton";
import { MainLine, StickyWrapper, ToolbarContent, ToolbarWrapper } from "./Toolbar.styles";
import { useToolbar } from "./ToolbarHooks";

export const Toolbar = () => {
  const isMobile = useHasMaxWidth(Breakpoints.XS);
  const { isOpen, visible } = useAppSelector(selectToolbar);
  const { toolbar } = useToolbar();

  return visible ? (
    <>
      <ToolbarWrapper data-testid="filters-toolbar">
        {isMobile ? <FilterMobileButton /> : <FiltersButton />}
        <ToolbarContent $isExpanded={isOpen} $isMobile={isMobile}>
          <StickyWrapper>
            <MainLine>
              <Headline bold>{toolbar.title}</Headline>
              <Stack>{toolbar?.head}</Stack>
              {isMobile && <FiltersButton />}
            </MainLine>
          </StickyWrapper>
          {toolbar.content}
        </ToolbarContent>
      </ToolbarWrapper>
      {toolbar?.modal}
    </>
  ) : null;
};
