import { Badge, ButtonCompact, ButtonText, ContextMenu, ContextMenuOption, Spacing, Stack } from "@secuis/ccp-react-components";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NotificationTypes } from "../../context/notifications/NotificationMessage";
import { NotificationsContext } from "../../context/notifications/NotificationsContext";
import { SENDOUT_STATUS, SENDOUT_TYPE, Sendout as SendoutModel } from "../../models/SendoutModel";
import { useAppDispatch, useAppSelector } from "../../store";
import { useDeleteSendoutMutation, useGetSendoutQuery, useLazyGetSendoutsQuery, useUpdateSendoutMutation } from "../../store/sendouts/SendoutsApi";
import { selectSendoutsFilters, selectSendoutsSelectedId } from "../../store/sendouts/SendoutsSelectors";
import { sendoutsActions } from "../../store/sendouts/SendoutsSlice";
import { toolbarActions } from "../../store/toolbar/ToolbarSlice";
import { getLocalDate } from "../../utilities/dateUtils";
import { getSendoutStatusColor } from "../../utilities/sendoutUtils";
import { ConfirmationModal } from "../Common/ConfirmationModal";
import { Parameter } from "../Items/List/Parameter";
import { DeleteButtonWrapper, ItemContainer } from "../Items/List/styled";
import { Buttons, SendoutParameters } from "./Sendout.styles";

interface SendoutProps {
  sendoutData: SendoutModel;
  last: boolean;
}

export const Sendout = ({ sendoutData, last }: SendoutProps) => {
  const { t, i18n } = useTranslation();
  const { toast } = useContext(NotificationsContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [deleteSendout] = useDeleteSendoutMutation();
  const selectedSendoutId = useAppSelector(selectSendoutsSelectedId);
  const filters = useAppSelector(selectSendoutsFilters);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [refetch] = useLazyGetSendoutsQuery();
  const { refetch: refetchSelected } = useGetSendoutQuery({ sendoutId: selectedSendoutId }, { skip: !selectedSendoutId, refetchOnMountOrArgChange: true });
  const [updateSendout, { isLoading: isUpdateSendoutLoading }] = useUpdateSendoutMutation();

  const onSelectSendout = () => {
    navigate(`/editSendout/${sendoutData.id}`);
    dispatch(
      toolbarActions.setToolbar({
        visible: true,
        contentType: "collection",
        storeType: "sendouts",
        isOpen: true,
      })
    );
  };

  const onPreview = (e) => {
    e.stopPropagation();
    dispatch(sendoutsActions.setPreviewedSendout(sendoutData?.id));
  };

  const cancelSendout = () => {
    deleteSendout({ id: sendoutData.id })
      .unwrap()
      .then(() => {
        toast({
          title: t("Sendout.canceled.toast.success"),
          type: NotificationTypes.Confirmation,
          icon: "Success",
        });
        refetch({ page: 1, filters });
        refetchSelected();
        navigate("/sendouts", { state: { openToolbar: true } });
      })
      .catch(() => true);
  };

  const moveBackSendoutToDraft = () => {
    updateSendout({
      sendoutId: sendoutData.id,
      status: SENDOUT_STATUS.draft,
    })
      .unwrap()
      .then(() => {
        toast({
          title: t("Sendout.updated.toast.success"),
          type: NotificationTypes.Confirmation,
          icon: "Success",
        });
        refetch({ page: 1, filters });
      })
      .catch(() => true);
  };

  const options: ContextMenuOption[] = [
    {
      icon: sendoutData.status === SENDOUT_STATUS.canceled ? "Reload" : "Error",
      label: sendoutData.status === SENDOUT_STATUS.canceled ? t("Sendout.backToDraft") : t("Common.cancel"),
      onClick: sendoutData.status === SENDOUT_STATUS.canceled ? moveBackSendoutToDraft : () => setIsOpen(true),
      disabled: sendoutData.status === SENDOUT_STATUS.published || (sendoutData.status === SENDOUT_STATUS.canceled && isUpdateSendoutLoading),
    },
  ];

  return (
    <>
      <ItemContainer selected={selectedSendoutId === sendoutData.id} onClick={onSelectSendout} style={{ cursor: "pointer" }}>
        <Stack direction="row">
          <SendoutParameters>
            <Parameter label={t("Filter.status")} small>
              <Badge label={t(`Sendout.status.${sendoutData.status}`)} mode="outlined" color={getSendoutStatusColor(sendoutData.status)} />
            </Parameter>
            <Parameter label={t("Sendout.label")} medium>
              {getLocalDate(sendoutData.schedule, i18n.language)}
            </Parameter>
            <Parameter label={t("Briefs.dropdown.createSendout")} small>
              {t(`reports.${SENDOUT_TYPE[sendoutData.type]}.title`)}
            </Parameter>
            <Parameter label={t("Briefs.selectedBriefs.label")} small>
              {sendoutData?.items?.length ?? "0"}
            </Parameter>
          </SendoutParameters>
          <Buttons>
            <ContextMenu
              align="right"
              options={options}
              sheetCancelLabel={t("Common.cancel")}
              sheetTitle={t("Common.cancel")}
              style={last ? { top: `-${Spacing.M * 3}px` } : {}}
            >
              <DeleteButtonWrapper>
                <ButtonText icon="Options" />
              </DeleteButtonWrapper>
            </ContextMenu>
            <ButtonCompact mode="contained" color="accent" onClick={(e) => onPreview(e)}>
              {t("Items.preview")}
            </ButtonCompact>
          </Buttons>
        </Stack>
      </ItemContainer>
      <ConfirmationModal isOpen={isOpen} setIsOpen={setIsOpen} action={cancelSendout} title={t("Sendout.buttons.cancel.confirmation")} />
    </>
  );
};
