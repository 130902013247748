import { useEffect, useState } from "react";
import {
  AwarenessReportAdmin,
  CreateReportRoles,
  ShareReportRoles,
  RiskIntelligenceAdmin,
  ShowContactUsRoles,
  ShowEmailRoles,
  BriefsOnlyRoles,
  ItemsHiddenRoles,
  ApproverRoles,
  ShareBriefRoles,
} from "../constants/roles";
import { useLoggedUser } from "../store/user/LoggedUserHooks";

export const useRoles = () => {
  const { userData: { roles } } = useLoggedUser();
  const [shouldShowContactUs, setShouldShowContactUs] = useState(false);
  const [couldCreateReport, setCouldCreateReport] = useState(null);
  const [canApprove, setCanApprove] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [shouldShowEmails, setShouldShowEmails] = useState(false);
  const [canShareReport, setCanShareReport] = useState(false);
  const [canSeeBriefs, setCanSeeBriefs] = useState(null);
  const [cantSeeItems, setCanSeeItems] = useState(null);
  const [canShareBrief, setCanShareBrief] = useState(null);

  useEffect(() => {
    if (roles.length > 0) {
      setShouldShowContactUs(roles.some(({ roleName }) => ShowContactUsRoles.includes(roleName)));
      setCouldCreateReport(roles.some(({ roleName }) => CreateReportRoles.includes(roleName)));
      setCanApprove(roles.some(({ roleName }) => ApproverRoles.includes(roleName)));
      setCanShareReport(roles.some(({ roleName }) => ShareReportRoles.includes(roleName)));
      setIsAdmin(roles.some(({ roleName }) => roleName === RiskIntelligenceAdmin || roleName === AwarenessReportAdmin));
      setShouldShowEmails(roles.some(({ roleName }) => ShowEmailRoles.includes(roleName)));
      setCanSeeBriefs(roles.some(({ roleName }) => BriefsOnlyRoles.includes(roleName)));
      setCanSeeItems(!roles.some(({ roleName }) => ItemsHiddenRoles.includes(roleName)));
      setCanShareBrief(roles.some(({ roleName }) => ShareBriefRoles.includes(roleName)))
    }
  }, [roles]);

  return {
    shouldShowContactUs,
    couldCreateReport,
    canApprove,
    isAdmin,
    shouldShowEmails,
    canShareReport,
    canSeeBriefs,
    cantSeeItems,
    canShareBrief
  };
};
