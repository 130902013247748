import { Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const CalendarWrapper = styled(Stack).attrs({
  justifyContent: "space-between",
}) <{ offset?: number }>`
 align-items: center;
   
  div[role="presentation"] {
    right: ${Spacing.XS}px;
    top: ${({ offset }) => {
    if (!!offset && offset - 500 < 0) {
      return offset
    } else if (!!offset && offset - 500 > 0) {
      return offset - 500;
    } else {
      return 160;
    }
  }}px;
  }
  @media(max-width: ${Breakpoints.XS}) {
      > div: first - child {
        > div: nth - child(2) {
        position: fixed;
      }
    }
    div[role = "presentation"] {
      top: 0px;
      right: 0px;
    }
  }
`;
