import { Button } from "@secuis/ccp-react-components";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationTypes } from "../../../../context/notifications/NotificationMessage";
import { NotificationsContext } from "../../../../context/notifications/NotificationsContext";
import { useUserData } from "../../../../hooks/useUserData";
import { Section } from "../../../../models/SectionModel";
import { useLoggedUser } from "../../../../store/user/LoggedUserHooks";
import { createSection, updateSection } from "../../../../utilities/userUtils";
import { areTagsChanged } from "../../../../utilities/utils";

const SaveSection = ({
  isChanged,
  savedReport,
  reportType,
  htmlContent,
  tags,
  setIsApproved,
  setUploadedDataSectionData,
  uploadedHtmlContent,
  savedTags,
  name,
  sectionIdElem,
  setElemSectionId,
  setIsReady,
  setCreator,
}) => {
  const { t } = useTranslation();
  const { userId } = useUserData();
  const { userData, userToken } = useLoggedUser();
  const { toast } = useContext(NotificationsContext);
  const [isCreationInProgress, setIsCreationInProgress] = useState(false);

  const saveReport = async () => {
    try {
      console.log("response");
      if (!sectionIdElem) {
        setIsCreationInProgress(true);
        const content = {
          name: name,
          content: htmlContent || "",
          order: 1,
          lang: "en",
          author: userId,
          is_done: false,
          is_approved: false,
          tags: tags.filter((elem) => elem.categories.length > 0),
        };
        const response = await createSection(userToken, savedReport, content);

        const sectionId = response.id;

        toast({
          title: t("CreateReport.toast.createSection", {
            type: t(`tag.${name}.region`),
          }),
          type: NotificationTypes.Confirmation,
          icon: "Success",
        });
        setIsApproved(false);

        setUploadedDataSectionData(tags, content.content);
        setCreator(userData.name);

        if (sectionId) {
          setElemSectionId(sectionId);
        }
        setIsCreationInProgress(false);
      } else {
        const content: Section = {};

        if (htmlContent !== uploadedHtmlContent) {
          content.content = htmlContent;
        }

        if (areTagsChanged(tags, savedTags)) {
          content.tags = tags.filter((elem) => elem.categories.length > 0);
        }

        await updateSection(userToken, savedReport, sectionIdElem, content);
        setUploadedDataSectionData(tags, htmlContent);
        const sectionName = t(`tag.${name}.region`);
        const type = `${reportType}: ${sectionName}\n`;
        toast({
          title: t("CreateReport.toast.updateSection", {
            type,
          }),
          type: NotificationTypes.Confirmation,
          icon: "Success",
        });

        setIsReady(false);
        setIsApproved(false);
      }
    } catch (e) {
      setIsCreationInProgress(false);
      toast({ title: t("Section.tootlip.updateFailed"), type: NotificationTypes.Error, icon: "Error" });
    }
  };

  return (
    <Button
      data-testid="SaveReportButton"
      icon="Archive"
      mode="contained"
      color="accent"
      disabled={!isChanged || isCreationInProgress}
      onClick={async () => saveReport()}
    >
      {t("Section.save")}
    </Button>
  );
};

export default SaveSection;
