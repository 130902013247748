import { CaseReducerActions } from "@reduxjs/toolkit";
import {
  Box,
  Breakpoints,
  Button,
  ButtonText,
  ComboBox,
  Dropdown,
  DropdownCheckbox,
  Input,
  Separator,
  Text,
  useHasMaxWidth,
} from "@secuis/ccp-react-components";
import { format } from "date-fns";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BriefFiltersModel } from "../../../../models/BriefFiltersModel";
import { ItemFiltersModel } from "../../../../models/ItemFiltersModel";
import { ITEM_STATUS } from "../../../../models/ItemModel";
import { IRootState, useAppDispatch, useAppSelector } from "../../../../store";
import { briefsApi } from "../../../../store/briefs/BriefsApi";
import { selectBriefsFilters } from "../../../../store/briefs/BriefsSelectors";
import { BriefsState, briefsActions } from "../../../../store/briefs/BriefsSlice";
import { itemsApi } from "../../../../store/items/ItemsApi";
import { selectItemsFilters, selectMetadata } from "../../../../store/items/ItemsSelectors";
import { ItemsState, itemsActions } from "../../../../store/items/ItemsSlice";
import { selectToolbar } from "../../../../store/toolbar/ToolbarSelectors";
import { DateNavigator } from "../../../DatePicker/DateNavigator";
import { ToolbarContentWrapper, ToolbarFooter, ToolbarFooterWrapper } from "../Toolbar.styles";
import { useToolbar } from "../ToolbarHooks";
import { DropdownWrapper, DropdownWrapperFixed } from "./FiltersContent.styles";

export interface FiltersContentProps {
  storeType?: "items" | "briefs";
}

export type CheckItem = {
  [key: string]: boolean;
};

type FilterType = {
  actions: CaseReducerActions<
    {
      resetFilters: (state: BriefsState | ItemsState) => void;
      setFilter: (
        state: BriefsState | ItemsState,
        action: {
          payload: any;
          type: string;
        }
      ) => void;
    },
    "items" | "briefs"
  >;
  filters: (value: IRootState) => ItemFiltersModel | BriefFiltersModel;
  fetch: any;
};

type StoreFiltersType = {
  items: FilterType;
  briefs: FilterType;
};

export const ItemsFiltersContent = () => {
  const { storeType } = useAppSelector(selectToolbar);
  const storeFiltersTypes: StoreFiltersType = useMemo(
    () => ({
      items: {
        actions: itemsActions,
        filters: selectItemsFilters,
        fetch: itemsApi.endpoints.getItems.initiate,
      },
      briefs: {
        actions: briefsActions,
        filters: selectBriefsFilters,
        fetch: briefsApi.endpoints.getBriefs.initiate,
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),
    [storeType]
  );

  const { t } = useTranslation();
  const isMobile = useHasMaxWidth(Breakpoints.XS);
  const { countries, region_daily, threat_risk_categories, threat_levels, sectors } = useAppSelector(selectMetadata);
  const dispatch = useAppDispatch();
  const filters = useAppSelector(storeFiltersTypes[storeType].filters) as ItemFiltersModel | BriefFiltersModel;
  const { isFilterSelectorExpanded, setIsFilterSelectorExpanded } = useToolbar();
  const [isChanged, setIsChanged] = useState(false);
  const [offset, setOffset] = useState(0);
  const [localFilters, setLocalFilters] = useState({ ...filters });

  const didMount = useRef(false);
  const dateRef = useRef(null);

  useEffect(() => {
    if (didMount.current) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
      didMount.current = true;
    }
  }, [localFilters]);

  const onExpandDatepickerChange = (ex: boolean) => {
    const { top } = dateRef.current.getBoundingClientRect();
    ex && setOffset(top);
    setIsFilterSelectorExpanded(ex);
  };

  const applyFilters = () => {
    dispatch(storeFiltersTypes[storeType].actions.setFilter({ ...localFilters }));
    dispatch(storeFiltersTypes[storeType].fetch({ page: 1, filters: localFilters }, { forceRefetch: true }));
    setIsChanged(false);
  };

  const handleBlurOnCountry = (e) => e.target.value === "" && setLocalFilters({ ...localFilters, country: "" });

  const onResetFilters = () => {
    didMount.current = false;
    setLocalFilters({});
    dispatch(storeFiltersTypes[storeType].actions.resetFilters());
    dispatch(storeFiltersTypes[storeType].fetch({ page: 1 }, { forceRefetch: true }));
  };

  return (
    <>
      <ToolbarContentWrapper $isFilterSelectorExpanded={isFilterSelectorExpanded}>
        <Separator />
        <DropdownWrapper>
          <Box pb="S" pt="S">
            <Text bold>{t("Filter.freeSearch")}</Text>
          </Box>
          <Input
            autoComplete="off"
            label={t("Filter.freeSearch")}
            placeholder={t("Filter.placeholder.freeSearch")}
            onChange={(event) => setLocalFilters({ ...localFilters, free_search: event.target.value })}
            value={(localFilters as ItemFiltersModel | BriefFiltersModel).free_search || ""}
          />
        </DropdownWrapper>
        {storeType === "items" && (
          <DropdownWrapperFixed>
            <Box pb="S" pt="S">
              <Text bold>{t("Filter.status")}</Text>
            </Box>
            <DropdownCheckbox
              onExpandChange={setIsFilterSelectorExpanded}
              onChange={(val) => setLocalFilters({ ...localFilters, item_statuses: val })}
              options={Object.values(ITEM_STATUS).map((status) => ({ value: status, title: t(`Filter.statuses.${status}`) }))}
              sheetCancelLabel="Cancel"
              values={(localFilters as ItemFiltersModel | BriefFiltersModel).item_statuses || []}
              placeholder={t("Filter.placeholder.all")}
            />
          </DropdownWrapperFixed>
        )}
        <DropdownWrapperFixed>
          <Box pb="S" pt="S">
            <Text bold>{t("Filter.threat_level")}</Text>
          </Box>
          <DropdownCheckbox
            onExpandChange={(ex) => setIsFilterSelectorExpanded(ex)}
            onChange={(val) => setLocalFilters({ ...localFilters, threat_levels: val })}
            options={threat_levels?.map(({ name }) => ({ value: name, title: t(`Items.threatLevels.${name}`) })) || []}
            sheetCancelLabel="Cancel"
            values={(localFilters as ItemFiltersModel | BriefFiltersModel).threat_levels || []}
            placeholder={t("Filter.placeholder.all")}
          />
        </DropdownWrapperFixed>
        <DropdownWrapperFixed>
          <Box pb="S" pt="S">
            <Text bold>{t("Items.filter.region")}</Text>
          </Box>
          <Dropdown
            onExpandChange={(ex) => setIsFilterSelectorExpanded(ex)}
            onChange={(val) => setLocalFilters({ ...localFilters, region: val })}
            options={region_daily.map((region) => ({ value: region, title: t(`tag.${region}.region`) }))}
            value={(localFilters as ItemFiltersModel | BriefFiltersModel).region}
            placeholder={t("Items.filter.placeholder.region")}
            maxHeight={450}
            sheetCancelLabel="Cancel"
          />
        </DropdownWrapperFixed>
        <DropdownWrapper>
          <Box pb="S" pt="S">
            <Text bold>{t("Filter.country")}</Text>
          </Box>
          <ComboBox
            onChange={(val) => setLocalFilters({ ...localFilters, country: val })}
            options={countries.map(({ name }) => ({ value: name, title: name }))}
            value={(localFilters as ItemFiltersModel | BriefFiltersModel).country}
            placeholder={t("Items.filter.placeholder.country")}
            onBlur={handleBlurOnCountry}
            maxHeight={isMobile ? 320 : 450}
          />
        </DropdownWrapper>
        <DropdownWrapper>
          <Box pb="S" pt="S">
            <Text bold>{t("Filter.city")}</Text>
          </Box>
          <Input
            autoComplete="off"
            label={t("Filter.city")}
            placeholder={t("Filter.placeholder.city")}
            onChange={(event) => setLocalFilters({ ...localFilters, settlement: event.target.value })}
            value={(localFilters as ItemFiltersModel | BriefFiltersModel).settlement || ""}
          />
        </DropdownWrapper>
        <DropdownWrapperFixed>
          <Box pb="S" pt="S">
            <Text bold>{t("Filter.tagsAndCategories")}</Text>
          </Box>
          <DropdownCheckbox
            onExpandChange={(ex) => setIsFilterSelectorExpanded(ex)}
            onChange={(val) => setLocalFilters({ ...localFilters, threat_risk_categories: val })}
            options={threat_risk_categories?.map(({ name }) => ({ value: name, title: t(`tag.${name}.name`) })) || []}
            sheetCancelLabel="Cancel"
            values={(localFilters as ItemFiltersModel | BriefFiltersModel).threat_risk_categories || []}
            placeholder={t("filter.selectCategories")}
          />
        </DropdownWrapperFixed>
        <DropdownWrapperFixed>
          <Box pb="S" pt="S">
            <Text bold>{t("Filter.sector")}</Text>
          </Box>
          <DropdownCheckbox
            onExpandChange={(ex) => setIsFilterSelectorExpanded(ex)}
            onChange={(val) => setLocalFilters({ ...localFilters, sector_specific: val })}
            options={sectors?.map(({ name }) => ({ value: name, title: name })) || []}
            sheetCancelLabel="Cancel"
            values={(localFilters as ItemFiltersModel | BriefFiltersModel).sector_specific || []}
            placeholder={t("Filter.sector")}
          />
        </DropdownWrapperFixed>
        <DropdownWrapper ref={dateRef}>
          <Box pb="S" pt="S">
            <Text bold>{t("Filter.date")}</Text>
          </Box>
          <DateNavigator
            offset={offset}
            onExpandChange={onExpandDatepickerChange}
            startDate={
              (localFilters as ItemFiltersModel | BriefFiltersModel)?.start_date
                ? new Date((localFilters as ItemFiltersModel | BriefFiltersModel).start_date)
                : null
            }
            endDate={
              (localFilters as ItemFiltersModel | BriefFiltersModel)?.end_date
                ? new Date((localFilters as ItemFiltersModel | BriefFiltersModel).end_date)
                : null
            }
            placeholder={t("CreateReport.schedule.selectDate")}
            setStartDate={(val) => setLocalFilters((state) => ({ ...state, start_date: !!val ? format(val, "yyyy-MM-dd") : "" }))}
            setEndDate={(val) => setLocalFilters((state) => ({ ...state, end_date: !!val ? format(val, "yyyy-MM-dd") : "" }))}
          />
        </DropdownWrapper>
      </ToolbarContentWrapper>
      <ToolbarFooter>
        <ToolbarFooterWrapper>
          <Button mode="contained" color="accent" disabled={!isChanged} onClick={applyFilters}>
            {t("filter.applyFilter")}
          </Button>
          <ButtonText color="accent" onClick={onResetFilters}>
            {t("Filter.reset")}
          </ButtonText>
        </ToolbarFooterWrapper>
      </ToolbarFooter>
    </>
  );
};
