import { Icon, Text } from "@secuis/ccp-react-components";
import { format, startOfTomorrow } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLocal } from "../../../../constants/generalSettings";
import { useSchedule } from "../../../../hooks/useSchedule";
import { useUserData } from "../../../../hooks/useUserData";
import { CalendarWrapper } from "../../../DatePicker/DateNavigator.styles";
import { ScheduleTimeComponent } from "../../../DatePicker/ScheduleTimeComponent";
import { ScheduleContainer, Wrapper } from "./DataDetailsComponentSections.styles";
import { UploadFileComponentSections } from "./UploadFileComponentSection";
import { CreatorSection } from "./styled";

export const DataDetailsComponentSections = ({
  reportCreation,
  setScheduleDate,
  scheduleDate,
  reportType,
  savedReport,
  attachmentId,
  setIsApproved,
  isDisabled,
  author,
}) => {
  const { t, i18n } = useTranslation();
  const { userToken } = useUserData();
  const { getSchedule, schedules } = useSchedule();
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(startOfTomorrow());
  const [endDate, setEndDate] = useState<Date | null>(startOfTomorrow());

  useEffect(() => {
    if (!scheduleDate) {
      const fetchDefaultSchedule = async () => {
        const nextSchedule = getSchedule(reportType);
        if (nextSchedule) {
          setScheduleDate(new Date(nextSchedule));
        }
      };

      fetchDefaultSchedule();
    }
  }, [schedules]);

  const hideCalendar = () => {
    setIsCalendarOpen(false);
  };

  const showCalendar = () => {
    setIsCalendarOpen(true);
  };

  return (
    <Wrapper isDisabled={isDisabled}>
      <div>
        <Text uppercase micro color="secondary">
          {t("NewReport.labelCreated")}:
        </Text>
        <CreatorSection>
          <Text data-testid="ReportCreationDate">
            {reportCreation.toLocaleString(i18n.language, {
              weekday: "short",
            })}
            , {format(reportCreation, "d MMM yyyy", { locale: getLocal(i18n.language) })}
          </Text>
          <Text data-testid="ReportCreationDate">{format(reportCreation, "HH:mm", { locale: getLocal(i18n.language) })}</Text>
          <Text data-testid="Author">{author}</Text>
        </CreatorSection>
      </div>
      <div>
        <Text uppercase micro color="secondary" data-testid="ReportScheduleTime">
          {t("NewReport.labelSchedule")}:
        </Text>
        <ScheduleContainer>
          <Icon size="L" variant="Calendar" color="secondary"></Icon>
          <Text data-testid="scheduleDate">
            {scheduleDate ? format(scheduleDate, "d MMM HH:mm", { locale: getLocal(i18n.language) }) : t("CreateReport.schedule.selectDate")}
          </Text>

          <CalendarWrapper data-testid="DateNavigator-Component">
            {!isDisabled && (
              <ScheduleTimeComponent
                data-testid="DateNavigator-ShowCalendar"
                showCalendar={showCalendar}
                hideCalendar={hideCalendar}
                isOpen={isCalendarOpen}
                scheduleDate={scheduleDate}
                setScheduleDate={setScheduleDate}
                startDate={startDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                endDate={endDate}
                lang={i18n.language}
              />
            )}
          </CalendarWrapper>
        </ScheduleContainer>
      </div>
      <div>
        <UploadFileComponentSections
          reportId={savedReport}
          userToken={userToken}
          attachmentId={attachmentId}
          setIsApproved={setIsApproved}
          isDisabled={isDisabled}
        />
      </div>
    </Wrapper>
  );
};
