import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../utilities/baseQuery";

export interface PreviewParams {
  entity_id: string;
  entity_type: string;
}

export interface PreviewResponse {
  entity_id: string;
  entity_type: string;
  format: string;
  contents: Contents;
}

interface Contents {
  detailed: string;
  abbreviated: string;
}

export const previewApi = createApi({
  reducerPath: "previewApi",
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    getPreview: builder.query<PreviewResponse, PreviewParams>({
      query: ({ entity_id, entity_type }) => ({
        url: `entity/${entity_id}/preview`,
        method: "GET",
        params: {
          entity_type,
        },
      }),
    }),
  }),
});

export const { useLazyGetPreviewQuery } = previewApi;
