import { Box, Button, Separator, Tab, Tabs, Text, Title } from "@secuis/ccp-react-components";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LANGUAGE_KEY } from "../../constants/generalSettings";
import { AppsEnum, IntercomTopic, useIntercom } from "../../hooks/useIntercom";
import { useReportTypes } from "../../hooks/useReportTypes";
import { useRoles } from "../../hooks/useRoles";
import { SENDOUT_TYPE } from "../../models/SendoutModel";
import { useAppSelector } from "../../store";
import { useLoggedUser } from "../../store/user/LoggedUserHooks";
import { selectPreferences } from "../../store/user/UserSelectors";
import { Content } from "../styled";
import { LanguageDropdown } from "./LanguageDropdown";
import { SliderSettings } from "./SliderSettings";
import { Card, SettingsContent } from "./styled";

export interface SettingsValue {
  value?: string;
  type?: string;
}

export const Preferences = () => {
  const { t } = useTranslation();
  const { reportsTypes } = useReportTypes();
  const { postMessage } = useIntercom();
  const preferences = useAppSelector(selectPreferences);
  const { userData } = useLoggedUser();
  const [shouldShowUserManagementLink, setShouldShowUserManagementLink] = useState(false);
  const { shouldShowEmails, isAdmin } = useRoles();

  useEffect(() => {
    if (userData && userData?.roles?.length > 0) {
      const isEmbed = userData?.isEmbedded || userData?.isEmbeddedInMobile;
      setShouldShowUserManagementLink(isAdmin && isEmbed);
    }
  }, [userData, isAdmin]);

  const filteredReportTypes = reportsTypes?.filter((reportType) => reportType !== "global_daily" && reportType !== "global_weekly");

  type TabChild = {
    disabled: boolean;
    content: () => React.JSX.Element;
  };

  const preferencesTabs: TabChild[] = [
    {
      disabled: false,
      content: () => (
        <Tab label={t("Menu.item6")}>
          {preferences && (
            <SettingsContent>
              <Card>
                <Box pb="S">
                  <Text uppercase micro color="secondary">
                    {t("Settings.notification.column.title")}
                  </Text>
                </Box>
                <Text small>{t("Settings.notification.column.subdescription")}</Text>

                {Object.keys(SENDOUT_TYPE)?.map((elem) => (
                  <Fragment key={elem}>
                    <Separator mv={"S"} />
                    <SliderSettings
                      title={`Sendout.${elem}.title`}
                      description={`Sendout.${elem}.description`}
                      key={`ric.bronze.sendouts.${elem}`}
                      elemKey={`ric.bronze.sendouts.${elem}`}
                      valueEmail={preferences[`ric.bronze.sendouts.${elem}.email`] as boolean}
                      valuePush={preferences[`ric.bronze.sendouts.${elem}.inapppush`] as boolean}
                      shouldShowEmails={shouldShowEmails}
                    />
                  </Fragment>
                ))}
              </Card>
            </SettingsContent>
          )}
        </Tab>
      ),
    },
    {
      disabled: false,
      content: () => (
        <Tab label={t("Menu.item1")}>
          {preferences && (
            <SettingsContent>
              <div>
                <Card>
                  <Box pb="S">
                    <Text uppercase micro color="secondary">
                      {t("Settings.notification.column.title")}
                    </Text>
                  </Box>
                  <Text small>{t("Settings.notification.column.subdescription")}</Text>

                  {filteredReportTypes?.map((elem) => (
                    <Fragment key={elem}>
                      <Separator mv={"S"} />
                      <SliderSettings
                        title={`reports.${elem}.title`}
                        description={`reports.${elem}.description`}
                        key={`ric.bronze.reports.${elem}`}
                        elemKey={`ric.bronze.reports.${elem}`}
                        valueEmail={preferences[`ric.bronze.reports.${elem}.email`] as boolean}
                        valuePush={preferences[`ric.bronze.reports.${elem}.inapppush`] as boolean}
                        shouldShowEmails={shouldShowEmails}
                      />
                    </Fragment>
                  ))}
                </Card>
              </div>
              <div>
                <Card>
                  <Box pb="S">
                    <Text uppercase micro color="secondary">
                      {t("Settings.otherSettings.column.LanguageTitle")}
                    </Text>
                  </Box>
                  <Text small bold>
                    {t("Settings.otherSettings.language")}
                  </Text>
                  <Text small>{t("Settings.otherSettings.description")}</Text>
                  <LanguageDropdown key={LANGUAGE_KEY} keyName={LANGUAGE_KEY} initValue={preferences[LANGUAGE_KEY] as string} />
                </Card>
              </div>
            </SettingsContent>
          )}
        </Tab>
      ),
    },
    {
      disabled: !shouldShowUserManagementLink,
      content: () => (
        <Tab label={t("ContactUs.Other")}>
          <SettingsContent>
            <Card>
              <Box pt="XS" pb="S">
                <Text small bold>
                  {t("Settings.otherSettings.column.OtherTitle")}
                </Text>
                <Text small>{t("Settings.otherSettings.userManagment")}</Text>
              </Box>
              <Button mode="outlined" onClick={() => postMessage(IntercomTopic.REDIRECT, AppsEnum.UserManagement)}>
                {t("Settings.button")}
              </Button>
            </Card>
          </SettingsContent>
        </Tab>
      ),
    },
  ];

  return (
    <Content>
      <Title>{t("Settings.title")}</Title>
      <Text>{t("Settings.subtitle")}</Text>
      <Box mt={"S"}>
        <Tabs>{preferencesTabs.filter(({ disabled }) => !disabled).map((tab) => tab.content())}</Tabs>
      </Box>
    </Content>
  );
};
