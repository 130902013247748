import { Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

interface ListContainerProps {
  $draftHeight?: number;
}

export const ListContainer = styled(Stack).attrs({
  direction: "column",
  gap: "XS",
})<ListContainerProps>`
  height: ${({ $draftHeight }) => `calc(100vh - ${$draftHeight}px - 150px)`};
  width: 100%;
  overflow-y: auto;
  scrollbar-width: auto;

  @media (max-width: ${Breakpoints.XS}) {
    padding-bottom: 200px;
  }

  .infinite-scroll-component {
    display: flex;
    flex-direction: column;
    gap: ${Spacing.XS}px;
    overflow: hidden !important;
  }
`;
