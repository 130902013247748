// data returned from BE uses snake_case whereas the URL for fetching it uses dash-case
export const METADATA_URl_PATHS = ["countries", "organizations", "regions", "sectors", "threat-actors", "threat-risk-categories", "threat-levels"];

interface CountryModel {
  id: number;
  name: string;
  capital: string;
  full_name: string;
  geolocation: { latitude: number; longitude: number };
  phone_code: string;
  ric_region_v1: string;
  ric_region_v2: string;
  ric_region_v3: string;
  unsd_region: string;
  Domain_code: string;
}

interface OrganizationModel {
  id: string;
  name: string;
}

interface RegionModel {
  region_daily: string[];
  region_weekly: string[];
  region_v3: string[];
}

interface SectorModel {
  id: number;
  name: string;
}

interface ThreatActorModel {
  id: number;
  name: string;
  description: string;
  is_deleted: boolean;
}

interface ThreatLevelModel {
  id: number;
  level: number;
  name: string;
  description: string;
}

interface ThreatRiskCategoryModel {
  id: number;
  name: string;
  is_deleted: boolean;
}

export interface ItemMetadataModel extends RegionModel {
  countries: CountryModel[];
  organizations: OrganizationModel[];
  sectors: SectorModel[];
  threat_actors: ThreatActorModel[];
  threat_levels: ThreatLevelModel[];
  threat_risk_categories: ThreatRiskCategoryModel[];
}
