import { ButtonCompact, ButtonIcon, Icon, Stack, Text } from "@secuis/ccp-react-components";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Item } from "../../../models/ItemModel";
import { getDateOfEvent } from "../../../utilities/Item.helpers";
import { Wrapper } from "./ItemPopup.styles";

interface ItemPopupProps {
  item: Item;
  onClose: () => void;
  onEdit: () => void;
  onPreview: () => void;
}

const ItemPopup: FC<ItemPopupProps> = ({ item, onClose, onEdit, onPreview }) => {
  const { t } = useTranslation();
  const location = `${item?.settlement ?? "-"}, ${item?.country ?? "-"} `;


  return (
    <Wrapper>
      <Stack justifyContent="flex-end" pb="XXS">
        <ButtonIcon mode="stateless" icon="Close" color="accent" onClick={onClose} />
      </Stack>
      <Stack direction="column" gap="XXS">
        <Text bold title={t("Item.title")}>
          {item.title}
        </Text>
        <Stack alignItems="center" gap="XXS" mt="XXS" title={t("Item.dateOfEvent")}>
          <Icon variant="Calendar" color="neutral" size="S" />
          <Text small>{getDateOfEvent(item)}</Text>
        </Stack>
        <Stack alignItems="center" gap="XXS" title={t("Items.location")}>
          <Icon variant="Location" color="neutral" size="S" />
          <Text small>{location}</Text>
        </Stack>
        <Stack alignItems="center" gap="XXS" title={t("Item.threatLevel")}>
          <Icon variant="Show" color="neutral" size="S" />
          <Text small>{item.threat_level ? t(`Items.threatLevels.${item.threat_level}`) : "-"}</Text>
        </Stack>
      </Stack>
      <Stack justifyContent="flex-end" gap="XS" mt="S">
        <ButtonCompact mode="contained" color="accent" onClick={onEdit}>
          {t("Common.edit")}
        </ButtonCompact>
        <ButtonCompact mode="contained" color="accent" onClick={onPreview}>
          {t("Items.preview")}
        </ButtonCompact>
      </Stack>
    </Wrapper>
  );
};

export default ItemPopup;
