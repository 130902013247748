import { Dropdown, Icon, Stack, Text, Toggle } from "@secuis/ccp-react-components";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store";
import { selectIsDraft } from "../../../store/item/ItemSelectors";
import { selectMetadata } from "../../../store/items/ItemsSelectors";
import { ChipsDropdown } from "./ChipsDropdown";
import { ItemTagsComponent } from "./ItemTagsComponent";
import { SourceLinksComponent } from "./SourceLinksComponent";

const ThreatsAndRisks: React.FC = () => {
  const { t } = useTranslation();
  const { control, getFieldState } = useFormContext();
  const { organizations, sectors, threat_actors, threat_levels } = useAppSelector(selectMetadata);
  const isDraft = useAppSelector(selectIsDraft);

  return (
    <>
      <Text bold>{t("Items.threatsRisks")}</Text>
      <Stack direction="row" gap="S" alignItems="center">
        <Text uppercase micro color="secondary">
          {t("Items.threatsRisksCategory")}
        </Text>
        {getFieldState("threat_risk_categories")?.invalid && <Icon size="S" variant="InfoFilled" color="error" />}
      </Stack>
      <Controller
        control={control}
        name="threat_risk_categories"
        defaultValue={[]}
        rules={{ required: !isDraft }}
        render={({ field }) => <ItemTagsComponent setTagsList={field.onChange} tagsList={field.value} {...field} />}
      />
      <Text uppercase micro color="secondary">
        {t("Items.threatLevel")}
      </Text>
      <Controller
        name="threat_level"
        control={control}
        defaultValue=""
        rules={{ required: !isDraft }}
        render={({ field, fieldState: { invalid } }) => (
          <Dropdown
            sheetCancelLabel="Cancel"
            label={t("Items.threatLevelPlaceholder")}
            placeholder={t("Items.threatLevelPlaceholder")}
            options={threat_levels.map(({ name, description }) => ({ value: name, title: description }))}
            invalid={invalid}
            {...field}
          />
        )}
      />
      <Text bold>{t("Items.threatRiskLevel")}</Text>
      <Controller
        name="threat_rating_security"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Dropdown
            sheetCancelLabel="Cancel"
            label={t("Items.security")}
            placeholder={t("Items.security")}
            options={threat_levels.map(({ name, description }) => ({ value: name, title: description }))}
            {...field}
          />
        )}
      />
      <Controller
        name="threat_rating_operations"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Dropdown
            sheetCancelLabel="Cancel"
            label={t("Items.operations")}
            placeholder={t("Items.operations")}
            options={threat_levels.map(({ name, description }) => ({ value: name, title: description }))}
            {...field}
          />
        )}
      />
      <Controller
        name="threat_rating_brand_and_reputation"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Dropdown
            sheetCancelLabel={t("Common.cancel")}
            label={t("Items.brandAndReputation")}
            placeholder={t("Items.brandAndReputation")}
            options={threat_levels.map(({ name, description }) => ({ value: name, title: description }))}
            {...field}
          />
        )}
      />
      <Text bold>{t("Items.threatActors")}</Text>
      <Controller
        control={control}
        name="threat_actors"
        defaultValue={[]}
        render={({ field }) => (
          <ChipsDropdown
            values={field.value}
            setValues={field.onChange}
            source={threat_actors.map(({ name }) => ({ value: name, title: name }))}
            placeholder={t("Items.concernedActivists")}
          />
        )}
      />
      <Stack direction="row" gap="S" alignItems="center">
        <Text bold>{t("Items.relevantLinks")}</Text>
        {getFieldState("source_links")?.invalid && <Icon size="S" variant="InfoFilled" color="error" />}
      </Stack>
      <Controller
        control={control}
        name="source_links"
        rules={{ required: !isDraft }}
        defaultValue={[]}
        render={({ field }) => <SourceLinksComponent links={field.value} setLinks={field.onChange} />}
      />
      <Stack direction="row" gap="S" alignItems="center">
        <Text bold>{t("Items.eventCancelled")}</Text>
        <Controller
          control={control}
          name="event_status"
          defaultValue=""
          render={({ field }) => (
            <Toggle
              id="event_status"
              checked={field.value === "canceled"}
              disabled={isDraft}
              onChange={() => (field.value === "canceled" ? field.onChange("ongoing") : field.onChange("canceled"))}
            />
          )}
        />
      </Stack>
      <Text bold>{t("Items.visibility")}</Text>
      <Controller
        control={control}
        name="externalOrInternal"
        render={({ field }) => (
          <Dropdown
            sheetCancelLabel="Cancel"
            label={t("Items.ExternalOrInternalBrief")}
            placeholder={t("Items.ExternalOrInternalBrief")}
            options={[
              { title: t("Items.external"), value: "external" },
              { title: t("Items.internal"), value: "internal" },
            ]}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="organization_specific_ids"
        defaultValue={[]}
        render={({ field }) => (
          <ChipsDropdown
            values={field.value}
            setValues={field.onChange}
            source={organizations.toSorted((a, b) => a.name.localeCompare(b.name)).map(({ id, name }) => ({ value: id, title: name }))}
            placeholder={t("Items.organisation")}
          />
        )}
      />
      <Controller
        control={control}
        name="sector_specific"
        defaultValue={[]}
        render={({ field }) => (
          <ChipsDropdown
            values={field.value}
            setValues={field.onChange}
            source={sectors.map(({ name }) => ({ value: name, title: name }))}
            placeholder={t("Items.sector")}
          />
        )}
      />
    </>
  );
};

export default ThreatsAndRisks;
