import { Button, ButtonText, Palette, Stack } from "@secuis/ccp-react-components";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import AttachmentIcon from "../../../../assets/icons/AttachmentIcon";
import PdfIcon from "../../../../assets/icons/PdfIcon";
import { NotificationTypes } from "../../../../context/notifications/NotificationMessage";
import { NotificationsContext } from "../../../../context/notifications/NotificationsContext";
import { useIntercom } from "../../../../hooks/useIntercom";
import { useLoggedUser } from "../../../../store/user/LoggedUserHooks";
import { downloadFile } from "../../../../utilities/fileUtils";
import { getDataFromBE } from "../../../../utilities/userUtils";

const AttachmentDownloadButton = ({ userToken, report, isPreview = false }) => {
  const { toast } = useContext(NotificationsContext);
  const { t } = useTranslation();
  const { userData } = useLoggedUser();
  const { postFile } = useIntercom();

  const handleDownload = async () => {
    try {
      const result = await getDataFromBE(userToken, `/reports/${report.report_id}/attachments/${report.attachment_ids[0]}`);
      if (userData.isEmbeddedInMobile) {
        postFile(result.data, result.file_name || "download.pdf");
      } else {
        downloadFile(result);
      }
    } catch (e) {
      toast({ title: t("settings.notification.failed"), type: NotificationTypes.Error, icon: "Error" });
    }
  };

  return isPreview ? (
    <Button mode="contained" color="accent" tabIndex={0} onClick={handleDownload}>
      <Stack alignItems="center" justifyContent="center" gap="XXS">
        <PdfIcon fill={Palette.White} />
        {t("ReportList.downloadPdf")}
      </Stack>
    </Button>
  ) : (
    <ButtonText tabIndex={0} onClick={handleDownload}>
      <AttachmentIcon />
    </ButtonText>
  );
};

export default AttachmentDownloadButton;
