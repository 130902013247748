import { ButtonCompact, Modal } from "@secuis/ccp-react-components";
import DOMPurify from "dompurify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store";
import { useLazyGetPreviewQuery } from "../../store/preview/PreviewApi";
import { selectPreview } from "../../store/preview/PreviewSelectors";
import { selectPreviewedSendout } from "../../store/sendouts/SendoutsSelectors";
import { sendoutsActions } from "../../store/sendouts/SendoutsSlice";
import { Container, StickyHeader } from "./SendoutPreviewModal.styles";

type PreviewType = "detailed" | "abbreviated";

export const SendoutPreviewModal = () => {
  const { t } = useTranslation();
  const sendoutId = useAppSelector(selectPreviewedSendout);
  const dispatch = useAppDispatch();
  const [getPreview] = useLazyGetPreviewQuery();
  const { contents: previews } = useAppSelector(selectPreview);
  const [previewType, setPreviewType] = useState<PreviewType>("detailed");
  const htmlContainerRef = useRef(null);

  useEffect(() => {
    if (sendoutId) {
      getPreview({ entity_id: sendoutId, entity_type: "sendout" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendoutId]);

  const handleOnClick = (previewType: PreviewType) => {
    setPreviewType(previewType);
    htmlContainerRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <Modal
      isOpen={!!sendoutId}
      size="L"
      title={t("SendoutPreview.previewEmailSendout")}
      onClose={() => {
        dispatch(sendoutsActions.clearPreviewedSendout());
      }}
    >
      {!!previews && (
        <>
          <StickyHeader>
            <ButtonCompact mode={previewType === "detailed" ? "contained" : "outlined"} onClick={() => handleOnClick("detailed")}>
              {t("SendoutPreview.full")}
            </ButtonCompact>
            <ButtonCompact mode={previewType === "abbreviated" ? "contained" : "outlined"} onClick={() => handleOnClick("abbreviated")}>
              {t("SendoutPreview.abbreviated")}
            </ButtonCompact>
          </StickyHeader>
          <Container
            ref={htmlContainerRef}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(previewType === "detailed" ? previews.detailed : previews.abbreviated) }}
          />
        </>
      )}
    </Modal>
  );
};
