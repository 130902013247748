import { createSlice } from "@reduxjs/toolkit";
import { previewApi, PreviewResponse } from "./PreviewApi";

export type PreviewState = {
  data: PreviewResponse;
};

const initialState: PreviewState = {
  data: {
    entity_id: "",
    entity_type: "",
    format: "",
    contents: undefined,
  },
};

export const previewSlice = createSlice({
  name: "preview",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(previewApi.endpoints.getPreview.matchFulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export const previewActions = previewSlice.actions;
