export const enum TRANSLATION_STATUS {
  NOT_TRANSLATED,
  TRANSLATED,
  ERROR,
}

export interface FetchSectionsProps {
  userToken: string;
  reportId: string;
}

export interface CreateNewFiltersProps {
  startDate: Date | null;
  endDate: Date | null;
  dropdown: string[];
  onlyFlagged: boolean;
  onlyUnpublished: boolean;
  checkedItems: string[];
}

export enum ViewMode {
  long = 0,
  short = 1,
  map = 2,
}
