import { Box, Dropdown } from "@secuis/ccp-react-components";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { LANGUAGE_DROPDOWN } from "../../config/i18next";
import { useUserData } from "../../hooks/useUserData";
import { useUser } from "../../store/user/UserHooks";

interface LanguageDropdownProps {
  keyName: string;
  initValue: string;
}

export const LanguageDropdown: FC<LanguageDropdownProps> = ({ keyName, initValue }) => {
  const langs = LANGUAGE_DROPDOWN;
  const [value, setValue] = useState(initValue);
  const { t } = useTranslation();
  const { userId } = useUserData();
  const { updatePreferences } = useUser(userId);

  const onChangeHandler = (value: string) => {
    setValue(value);
    updatePreferences(keyName, { value, type: "string" }, value);
  };

  return (
    <Box mt="S">
      <Dropdown
        data-testid="dropdown"
        onChange={onChangeHandler}
        options={langs.map((elem) => ({ title: t(`dropdown.language.${elem}`), value: elem }))}
        sheetCancelLabel="Cancel"
        value={value}
      />
    </Box>
  );
};
