export type SendoutModel = {
  schedule: Date;
  type: SENDOUT_TYPE;
  item_ids: string[];
};

export enum SENDOUT_STATUS {
  draft = "draft",
  published = "published",
  unpublished = "unpublished",
  canceled = "canceled",
};

export type SendoutStatus = keyof typeof SENDOUT_STATUS;

export enum SENDOUT_TYPE {
  daily = "global_daily",
  weekly = "global_weekly",
};

export type SendoutType = keyof typeof SENDOUT_TYPE;

export type Sendout = {
  id: string;
  status: SendoutStatus;
  schedule: string;
  type: string;
  date_added: string;
  added_by: string;
  date_last_updated: string;
  last_updated_by: string;
  items?: string[];
};