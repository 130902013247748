import { Button } from "@secuis/ccp-react-components";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NotificationTypes } from "../../../../context/notifications/NotificationMessage";
import { NotificationsContext } from "../../../../context/notifications/NotificationsContext";
import { useRoles } from "../../../../hooks/useRoles";
import { useUserData } from "../../../../hooks/useUserData";
import { useLoggedUser } from "../../../../store/user/LoggedUserHooks";
import { updateSection } from "../../../../utilities/userUtils";
import { ApproveSectionWrapper } from "./styled";

const ApproveSection = ({ isChanged, isApproved, isReady, elemSectionId, savedReport, setApprover, setIsApproved }) => {
  const { t } = useTranslation();
  const { userId } = useUserData();
  const { userData, userToken } = useLoggedUser();
  const { canApprove } = useRoles();
  const { toast } = useContext(NotificationsContext);

  const onApprove = async () => {
    try {
      await updateSection(userToken, savedReport, elemSectionId, { is_approved: true, approval_user: userId });
      setApprover(userData.name);
      setIsApproved(true);
      toast({
        title: t("Section.tootlip.approvedSection"),
        type: NotificationTypes.Confirmation,
        icon: "Success",
      });
    } catch (e) {
      toast({
        title: t("Section.tootlip.updateFailed"),
        type: NotificationTypes.Error,
        icon: "Error",
      });
    }
  };

  return (
    <ApproveSectionWrapper>
      <Button
        data-testid="ApproveButton"
        icon="ValidatedCheck"
        mode="contained"
        color="accent"
        disabled={isChanged || isApproved || !isReady || !canApprove}
        onClick={onApprove}
      >
        {t("Section.approve")}
      </Button>
    </ApproveSectionWrapper>
  );
};

export default ApproveSection;
