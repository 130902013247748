import { Badge, Textarea } from "@secuis/ccp-react-components";
import React, { TextareaHTMLAttributes, useEffect, useRef, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Content, FloatingButton } from "./TextareaWithBulletPoints.styles";

interface TextareaWithBulletPointsProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  invalid?: boolean;
  fieldName: string;
}

export const TextareaWithBulletPoints = ({ label, disabled, invalid, fieldName, ...field }: Readonly<TextareaWithBulletPointsProps>) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const [focused, setFocused] = useState(false);
  const [caretPosition, setCaretPosition] = useState(0);
  const [bulletPointAdded, setBulletPointAdded] = useState(false);

  const text = useWatch({ name: fieldName });
  const ref = useRef(null);

  const handleBlur = (e) => {
    if (e.relatedTarget === null) {
      setCaretPosition(e.currentTarget.selectionStart);
      e.target.focus();
    } else {
      setFocused(false);
    }
  };

  const handleFocus = () => setFocused(true);

  const handleBulletPointAdd = () => {
    if (ref.current.querySelector("textarea")) {
      const newValue = !text ? " • " : text.slice(0, caretPosition) + " • " + text.slice(caretPosition);
      setValue(fieldName, newValue);
      setBulletPointAdded(true);
    }
  };

  useEffect(() => {
    if (bulletPointAdded && ref.current) {
      const newCaretPosition = caretPosition + 3;
      ref.current.querySelector("textarea").setSelectionRange(newCaretPosition, newCaretPosition);
      setBulletPointAdded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulletPointAdded]);

  return (
    <Content>
      <div ref={ref}>
        <Textarea invalid={invalid} label={label} placeholder={label} {...field} onBlur={handleBlur} onFocus={handleFocus} />
      </div>
      <FloatingButton active={focused}>
        <Badge label={`${t("common.add")} " • "`} onClick={handleBulletPointAdd} uppercase={false} />
      </FloatingButton>
    </Content>
  );
};
