import { Box, Button, LoadingIndicator, Stack, Text, Title } from "@secuis/ccp-react-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LANGUAGE_KEY } from "../../constants/generalSettings";
import { AppsEnum, IntercomTopic, useIntercom } from "../../hooks/useIntercom";
import { useReportTypes } from "../../hooks/useReportTypes";
import { useRoles } from "../../hooks/useRoles";
import { useTagsAndRegions } from "../../hooks/useTagsAndRegions";
import { useAppSelector } from "../../store";
import { useLoggedUser } from "../../store/user/LoggedUserHooks";
import { selectPreferences } from "../../store/user/UserSelectors";
import { Content } from "../styled";
import { DropdownSettings } from "./DropdownSettings";
import { LanguageDropdown } from "./LanguageDropdown";
import { SliderSettings } from "./SliderSettings";
import { Card, CustomSeparator, LoadingWrapper, SettingsContent } from "./styled";

export interface SettingsValue {
  value?: string;
  type?: string;
}

export const Settings = () => {
  const { t } = useTranslation();
  const { reportsTypes } = useReportTypes();
  const { initRegions } = useTagsAndRegions();
  const { postMessage } = useIntercom();
  const { userData } = useLoggedUser();
  const [shouldShowUserManagementLink, setShouldShowUserManagementLink] = useState(false);
  const { shouldShowEmails, isAdmin } = useRoles();
  const preferences = useAppSelector(selectPreferences);

  useEffect(() => {
    if (userData.roles?.length > 0) {
      const isEmbed = userData.isEmbedded || userData.isEmbeddedInMobile;
      setShouldShowUserManagementLink(isAdmin && isEmbed);
    }
  }, [userData]);

  return (
    <Content>
      <Title>{t("Settings.title")}</Title>
      <Text>{t("Settings.subtitle")}</Text>
      {preferences ? (
        <SettingsContent>
          <div>
            <Card>
              <Box pb="S">
                <Text uppercase micro color="secondary">
                  {t("Settings.notification.column.title")}
                </Text>
              </Box>
              <Text small>{t("Settings.notification.column.subdescription")}</Text>

              {reportsTypes?.map((elem) => (
                <>
                  <CustomSeparator />
                  <SliderSettings
                    title={`reports.${elem}.title`}
                    description={`reports.${elem}.description`}
                    key={`ric.bronze.reports.${elem}`}
                    elemKey={`ric.bronze.reports.${elem}`}
                    valueEmail={preferences[`ric.bronze.reports.${elem}.email`] as boolean}
                    valuePush={preferences[`ric.bronze.reports.${elem}.inapppush`] as boolean}
                    shouldShowEmails={shouldShowEmails}
                  ></SliderSettings>
                </>
              ))}
            </Card>
          </div>
          <div>
            <Card>
              <Box pb="S">
                <Text uppercase micro color="secondary">
                  {t("Settings.specialNotification.column.title")}
                </Text>
              </Box>
              <Text small>{t("Settings.specialNotification.column.subdescription")}</Text>

              <Stack direction="column" gap="M" pt="S">
                {initRegions.map((elem) => (
                  <DropdownSettings key={`dropdown-${elem.region}`} title={elem.region} />
                ))}
              </Stack>
            </Card>
          </div>
          <div>
            <Card>
              <Box pb="S">
                <Text uppercase micro color="secondary">
                  {t("Settings.otherSettings.column.LanguageTitle")}
                </Text>
              </Box>
              <Text small bold>
                {t("Settings.otherSettings.language")}
              </Text>
              <Text small>{t("Settings.otherSettings.description")}</Text>

              <LanguageDropdown key={LANGUAGE_KEY} keyName={LANGUAGE_KEY} initValue={preferences[LANGUAGE_KEY] as string} />

              {shouldShowUserManagementLink && (
                <>
                  <CustomSeparator />
                  <Box pt="XS" pb="S">
                    <Text small bold>
                      {t("Settings.otherSettings.column.OtherTitle")}
                    </Text>

                    <Text small>{t("Settings.otherSettings.userManagment")}</Text>
                  </Box>
                  <Button
                    mode="contained"
                    onClick={() => {
                      postMessage(IntercomTopic.REDIRECT, AppsEnum.UserManagement);
                    }}
                  >
                    {t("Settings.button")}
                  </Button>
                </>
              )}
            </Card>
          </div>
        </SettingsContent>
      ) : (
        <LoadingWrapper>
          <LoadingIndicator size="L" />
        </LoadingWrapper>
      )}
    </Content>
  );
};
