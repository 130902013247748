import { Datepicker, DatepickerInput } from "@secuis/ccp-react-components";
import React, { useEffect, useState } from "react";

import { endOfYear, getYear, startOfYear, sub, subDays, subMonths } from "date-fns";
import { useTranslation } from "react-i18next";
import { CalendarWrapper } from "./DateNavigator.styles";

interface DateNavigatorProps {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  blockFutureFromDays?: number;
  blockPastBeforeDays?: number;
  placeholder?: string;
  onExpandChange?: (value: boolean) => void;
  offset?: number;
}

export const DateNavigator = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  blockFutureFromDays,
  blockPastBeforeDays,
  placeholder,
  onExpandChange,
  offset = 0,
}: DateNavigatorProps) => {
  const { t, i18n } = useTranslation();
  const currentDate = new Date();
  const futureYears = [currentDate, sub(currentDate, { years: 1 })];
  const chips = [
    {
      label: t("datePicker.dateRages.last7Days"),
      from: subDays(new Date(), 6),
    },
    {
      label: t("datePicker.dateRages.last30Days"),
      from: subDays(new Date(), 29),
    },
    {
      label: t("datePicker.dateRages.last90Days"),
      from: subDays(new Date(), 89),
    },
    {
      label: t("datePicker.dateRages.last365Days"),
      from: subMonths(new Date(), 12),
    },
    {
      label: getYear(futureYears[1]).toString(),
      from: startOfYear(new Date(futureYears[1].toString())),
      to: endOfYear(new Date(futureYears[1].toString())),
    },
    {
      label: getYear(futureYears[0]).toString(),
      from: startOfYear(new Date(futureYears[0].toString())),
      to: endOfYear(new Date(futureYears[0].toString())),
    },
  ];
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const showCalendar = () => {
    setIsOpen(true);
  };

  const clearDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const hideCalendar = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (onExpandChange) {
      onExpandChange(isOpen);
    }
  }, [isOpen]);

  return (
    <CalendarWrapper data-testid="DateNavigator-Component" offset={offset}>
      <DatepickerInput
        data-testid="DateNavigator-ShowCalendar"
        startDate={startDate}
        endDate={endDate}
        clearDates={clearDates}
        showCalendar={showCalendar}
        placeholder={placeholder}
        label={t("Filter.date")}
        isOpen={isOpen}
        languageCode={i18n.language}
      >
        <Datepicker
          mode="singleMonth"
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          blockFutureFromDays={blockFutureFromDays}
          blockPastBeforeDays={blockPastBeforeDays}
          hideCalendar={hideCalendar}
          saveButtonLabel={t("Filter.date")}
          languageCode={i18n.language}
          dateRangeChips={chips}
        />
      </DatepickerInput>
    </CalendarWrapper>
  );
};
