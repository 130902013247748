import { createSlice } from '@reduxjs/toolkit'
import { getPreparedData } from '../../utilities/reportUtils'
import { FetchStatus, Reports } from "../../models/ReportModel";
import { fetchReportsThunk } from './ReportsThunkActions';
import { userApi } from '../user/UserApi';

export type ReportState = {
  data: Reports;
  status: FetchStatus;
  page: number;
  showMore: boolean;
};

export const initialState: ReportState = {
  data: {
    filteredReports: [],
    categorizedDates: {},
  },
  page: 1,
  showMore: false,
  status: 'idle'
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchReportsThunk.pending, (state, action) => {
        state.status = action.meta.arg.page === 1 ? action.meta.requestStatus : state.status;
      })
      .addCase(fetchReportsThunk.rejected, (state, action) => {
        state.status = action.meta.requestStatus
      })
      .addCase(fetchReportsThunk.fulfilled, (state, action) => {
        if(action.payload?.data) {
          const fetchReports = action.meta.arg.page === 1 ? action.payload.data : [...state.data.filteredReports, ...action.payload.data];
          const filteredReports = getPreparedData({ fetchedReports: fetchReports, couldCreateReport: action.meta.arg.couldCreateReport});
          state.data = {
            filteredReports: filteredReports.reports,
            categorizedDates: filteredReports.categorizedDates,
          }
          state.showMore = action.payload.data.length > 0;
          state.status = action.meta.requestStatus;
          state.page = action.payload.meta.page ? action.payload.meta.page : state.page;
        }
      })
      .addMatcher(userApi.endpoints.setInteractions.matchFulfilled, (state, action) => {
        if(action.payload.entity_type === "report") {
          state.data.filteredReports = state.data.filteredReports.map((r) => 
            action.payload.entity_id === r.report_id
              ? { ...r, user_interaction: { ...r.user_interaction, viewed: action.payload.viewed, flagged: action.payload.flagged } }
              : r
          );
        }
      });
  }
});

export const reportsActions = reportsSlice.actions;