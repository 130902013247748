import { useStore } from "react-context-hook";

export const useUserData = () => {
  const [userId, setUserIdDeprecated] = useStore("userId", "");
  const [userToken, setUserTokenDeprecated] = useStore("userToken", "");

  return {
    userId,
    setUserIdDeprecated,
    userToken,
    setUserTokenDeprecated,
  };
};
