import { Icon, LoadingIndicator, Stack, Text } from "@secuis/ccp-react-components";
import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationTypes } from "../../../context/notifications/NotificationMessage";
import { NotificationsContext } from "../../../context/notifications/NotificationsContext";
import { useUserData } from "../../../hooks/useUserData";
import { Report } from "../../../models/ReportModel";
import { useAppSelector } from "../../../store";
import { selectLanguage } from "../../../store/user/UserSelectors";
import { TRANSLATION_STATUS } from "../../../utilities/types";
import { getReportContent } from "../../../utilities/utils";
import { Wrapper } from "../Create/Preview/Preview.styles";
import { PreviewContentElem } from "../Create/Preview/PreviewContentElem";
import AttachmentDownloadButton from "../List/ReportActions/AttachmentDownloadButton";
import { AttachmentWrapper } from "../List/styled";

interface WrappedPreviewProps {
  report: Report;
  isLoading?: boolean;
  isFromReportList?: boolean;
}

export const WrappedPreview: FC<WrappedPreviewProps> = ({ report, isLoading, isFromReportList = true }) => {
  const { t } = useTranslation();
  const { userToken } = useUserData();
  const lang = useAppSelector(selectLanguage);
  const [status, setStatus] = useState<TRANSLATION_STATUS>();
  const { toast } = useContext(NotificationsContext);
  const htmlContent = getReportContent(report);

  useEffect(() => {
    if (report) {
      const reportLang = report.sections?.[0]?.lang;

      if (!reportLang || lang === "en") {
        setStatus(TRANSLATION_STATUS.NOT_TRANSLATED);
      } else if (reportLang === lang) {
        setStatus(TRANSLATION_STATUS.TRANSLATED);
      } else {
        toast({
          title: t("toast.translationError.title"),
          message: t("toast.translationError.description", { language: t(`dropdown.language.${lang}`) }),
          type: NotificationTypes.Error,
          icon: "Error",
        });
        setStatus(TRANSLATION_STATUS.NOT_TRANSLATED);
      }
    }
  }, [report?.report_id, lang]);

  return (
    <Wrapper data-testid="preview" $isMobile={false} isFromReportList={isFromReportList}>
      {isLoading ? (
        <LoadingIndicator size="S" />
      ) : (
        <>
          {status === TRANSLATION_STATUS.TRANSLATED && (
            <Stack gap="XS">
              <Icon color="secondary" variant="InfoFilled" />
              <Text small color="secondary">
                {t("reports.ai-indicator")}
              </Text>
            </Stack>
          )}
          <PreviewContentElem $isMobile={false} isFromReportList={isFromReportList} htmlContent={htmlContent} />
          {report?.attachment_ids?.length > 0 && (
            <AttachmentWrapper>
              <AttachmentDownloadButton userToken={userToken} report={report} isPreview />
            </AttachmentWrapper>
          )}
        </>
      )}
    </Wrapper>
  );
};
